


export function getPiezas() {
    const var1 = localStorage.getItem("token");
    let token = JSON.parse(var1);
    let accessToken = token ? token["access_token"] : undefined;
    return (
        fetch("/api/pieza/all", {
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            }
        })
    );
}

export function getPieza(id) {
    const var1 = localStorage.getItem("token");
    let token = JSON.parse(var1);
    let accessToken = token ? token["access_token"] : undefined;
    return (
        fetch("/api/pieza/" + id, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            }
        })
    );
}

export function postPieza(datos) {
    const var1 = localStorage.getItem("token");
    let token = JSON.parse(var1);
    let accessToken = token ? token["access_token"] : undefined;
    return (
        fetch("/api/pieza/insert", {
            method: "POST",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(datos),
        })
    );
}

export function putPieza(id, datos) {
    const var1 = localStorage.getItem("token");
    let token = JSON.parse(var1);
    let accessToken = token ? token["access_token"] : undefined;
    return (
        fetch("/api/pieza/update/" + id, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(datos),
        })
    );
}

export function deletePieza(id) {
    const var1 = localStorage.getItem("token");
    let token = JSON.parse(var1);
    let accessToken = token ? token["access_token"] : undefined;
    return (
        fetch("/api/pieza/delete/" + id, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            }
        })
    );
}
