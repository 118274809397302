import { useState } from "react";
import { Dialog } from "@mui/material";
import IngButton from "../atomos/IngButton";
import "./Dialog.css";
import CircularProgress from "@mui/material/CircularProgress";
import { enviarPdfAdmin } from "../../services/APIPresupuesto";
import Auth from "../../Auth";

/**
 * Dialog para enviar correo a administracion
 * @name EnviarAdministracionDialog
 * @Component
 * @param {Object} props – parametros de la clase
 * @param {boolean} props.open – Estado de apertura del dialog
 * @param {*} props.onClose – Funcion de cerrar el dialog
 * @param {function} props.actualizar – funcion a ejecutar al aceptar
 * @param {number} props.presupuestoId – Id del presupuesto
 */
export default function SelectEmailDialog(props) {
  const { open, onClose, actualizar, presupuestoId } = props;
  const [enviado, setEnviado] = useState(true);

  const sendEmail = () => {
    setEnviado(false);
    enviarPdfAdmin(presupuestoId)
      .then((res) =>
        res.status === 401 ? Auth.logout() : res.ok ? res.json() : null
      )
      .then((json) => {
        if (json) {
          setEnviado(true);
          actualizar();
          onClose();
        } else {
          setEnviado(false);
          onClose();
        }
      });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <div className="dialog--one">
        {enviado ? (
          <>
            <div className="dialog--one--column">
              <div className="dialog--one--title">
                <h3>Enviar presupuesto:</h3>
              </div>
              ¿Seguro que desea enviar este presupuesto a administración?
            </div>
            <div className="dialog--one--buttons">
              <IngButton
                onClick={() => sendEmail()}
                variant="contained"
                label="Aceptar"
                color="secondary"
              />
              <IngButton
                onClick={onClose}
                variant="contained"
                color="error"
                label="Cancelar"
              ></IngButton>
            </div>
          </>
        ) : (
          <div className="dialog--one">
            <h3>Enviando a administración...</h3>
            <CircularProgress />
          </div>
        )}
      </div>
    </Dialog>
  );
}
