import React from "react";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#7D141B",
    },
    secondary: {
      main: "#3A86A2",
    },
    error: {
      main: "#FC5454",
    },
    warning: {
      main: "#FDF55E",
    },
    info: {
      main: "#00348e",
    },
    success: {
      main: "#179A54",
    },
  },
});
/**
 * Ing Botón personalizado
 * @name BotonIngesis
 * @Component
 * @param {Object} props – parametros de la clase
 * @param {String} [props.className] - estilo personalizado del botón
 * @param {"text" | "outlined" | "contained"} [props.variant] - modo visual del botón, outlined por defecto
 * @param {React.MouseEventHandler<HTMLButtonElement>} [props.onClick] - función onClick del botón
 * @param {"primary" | "secondary" | "error" | "warning" | "info" | "success"} [props.color] - color del botón
 * @param {React.ReactNode} [props.endIcon] - icono para mostrar en el botón
 * @param {"small" | "medium" | "large"} [props.size] - tamaño botón
 * @param {boolean} [props.disabled] - boolean que controla la habilitación/deshabilitación del botón
 * @param {String} [props.label] - texto del botón
 * @param {"button" | "submit" | "reset"} [props.type] - type del botón
 */
export default function IngButton(props) {
  return (
    <ThemeProvider theme={theme}>
      <Button
        className={props.className}
        variant={props.variant ? props.variant : "outlined"}
        onClick={props.onClick}
        color={props.color}
        endIcon={props.endIcon}
        size={props.size}
        disabled={props.disabled}
        type={props.type}
      >
        {props.label}
      </Button>
    </ThemeProvider>
  );
}
