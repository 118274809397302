import { useEffect } from "react";
import { Dialog } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import IngButton from "../atomos/IngButton";
import IngTextField from "../atomos/IngTextField";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "./Dialog.css";
import IngTFNumeric from "../atomos/IngTFNumeric";
import Auth from "../../Auth";
import {
  getContacto,
  postContacto,
  putContacto,
} from "../../services/APIContacto";

/**
 * Dialog para crear o modificar contactos
 * @name ContactoDialog
 * @Component
 * @param {Object} props – parametros de la clase
 * @param {boolean} props.open – Estado de apertura del dialog
 * @param {*} props.onClose – Funcion de cerrar el dialog
 * @param {function} props.actualizar – funcion a ejecutar al aceptar
 * @param {number} props.id – Id del contacto
 * @param {number} props.clienteId - Id del cliente
 */
export default function ContactoDialog(props) {
  const { open, onClose, actualizar, id, clienteId } = props;
  const schema = yup
    .object({
      nombre: yup.string().required("Debes de introducir un nombre."),
      cargo: yup.string(),
      telefono: yup.string().typeError("El telefono debe de ser numérico"),
      email: yup
        .string()
        .email("Escribe un email válido.")
        .required("Debes introducir un email."),
      comentarios: yup
        .string()
        .max(255, "El comentario no puede superar los 255 carácteres."),
    })
    .required();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      nombre: "",
      cargo: "",
      telefono: "",
      email: "",
      comentarios: "",
      clienteId: clienteId,
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (id !== 0 && open) {
      getContacto(id)
        .then((res) =>
          res.status === 401 ? Auth.logout() : res.ok ? res.json() : null
        )
        .then((json) => reset(json));
    } else {
      reset({
        nombre: "",
        cargo: "",
        telefono: "",
        email: "",
        comentarios: "",
        clienteId: clienteId,
      });
    }
  }, [id, reset, onClose, clienteId]);

  const onSubmit = (data) => {
    // const request = {
    //   method: data.id ? "PUT" : "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify(data),
    // };
    data.id
      ? putContacto(data.id, data)
          .then((res) =>
            res.status === 401 ? Auth.logout() : res.ok ? res.json() : null
          )
          .then((json) => {
            if (json) {
              actualizar();
              onClose();
            }
          })
      : postContacto(data)
          .then((res) =>
            res.status === 401 ? Auth.logout() : res.ok ? res.json() : null
          )
          .then((json) => {
            if (json) {
              actualizar();
              onClose();
            }
          });
  };

  return (
    <Dialog open={open} onClose={onClose} onSubmit={handleSubmit(onSubmit)}>
      <form className="dialog--contacto">
        <div className="dialog--contacto--content">
          <div className="dialog--contacto--title">
            <h3>{id === 0 ? "Crear " : "Modificar "}contacto:</h3>
          </div>
          <div className="dialog--contacto--row">
            <div className="dialog--contacto--column">
              <Controller
                name="nombre"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <IngTextField
                    label="Nombre: "
                    onChange={onChange}
                    value={value}
                    errors={errors.nombre?.message}
                  />
                )}
              />
              <Controller
                name="telefono"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <IngTFNumeric
                    label="Teléfono: "
                    onChange={onChange}
                    value={value}
                    errors={errors.telefono?.message}
                  />
                )}
              />
            </div>
            <div className="dialog--contacto--column">
              <Controller
                name="cargo"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <IngTextField
                    label="Cargo: "
                    onChange={onChange}
                    value={value}
                    errors={errors.cargo?.message}
                  />
                )}
              />
              <Controller
                name="email"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <IngTextField
                    label="Email: "
                    onChange={onChange}
                    value={value}
                    errors={errors.email?.message}
                  />
                )}
              />
            </div>
          </div>
          <div className="dialog--contacto--comentarios">
            <Controller
              name="comentarios"
              control={control}
              render={({ field: { onChange, value } }) => (
                <IngTextField
                  label="Comentarios: "
                  onChange={onChange}
                  value={value}
                  multiline={true}
                  errors={errors.comentarios?.message}
                />
              )}
            />
          </div>
        </div>
        <div className="dialog--one--buttons">
          <IngButton
            type="submit"
            variant="contained"
            label="Aceptar"
            color="secondary"
          ></IngButton>
          <IngButton
            onClick={onClose}
            variant="contained"
            color="error"
            label="Cancelar"
          ></IngButton>
        </div>
      </form>
    </Dialog>
  );
}
