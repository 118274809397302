


export function getPrecios() {
    const var1 = localStorage.getItem("token");
    let token = JSON.parse(var1);
    let accessToken = token ? token["access_token"] : undefined;
    return (
        fetch("/api/precio/all", {
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            }
        })
    );
}

export function getPrecio(id) {
    const var1 = localStorage.getItem("token");
    let token = JSON.parse(var1);
    let accessToken = token ? token["access_token"] : undefined;
    return (
        fetch("/api/precio/" + id, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            }
        })
    );
}

export function postPrecio(datos) {
    const var1 = localStorage.getItem("token");
    let token = JSON.parse(var1);
    let accessToken = token ? token["access_token"] : undefined;
    return (
        fetch("/api/precio/insert", {
            method: "POST",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(datos),
        })
    );
}
export function postPrecioRecomendado(datos) {
    const var1 = localStorage.getItem("token");
    let token = JSON.parse(var1);
    let accessToken = token ? token["access_token"] : undefined;
    return (
        fetch("/api/precio/recomendado", {
            method: "POST",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(datos),
        })
    );
}

export function putPrecio(id, datos) {
    const var1 = localStorage.getItem("token");
    let token = JSON.parse(var1);
    let accessToken = token ? token["access_token"] : undefined;
    return (
        fetch("/api/precio/update/" + id, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(datos),
        })
    );
}

export function deletePrecio(id) {
    const var1 = localStorage.getItem("token");
    let token = JSON.parse(var1);
    let accessToken = token ? token["access_token"] : undefined;
    return (
        fetch("/api/precio/delete/" + id, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            }
        })
    );
}
