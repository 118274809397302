import React, { useEffect, useContext } from "react";
import IngCard from "../moleculas/Ing_Card";
import { useNavigate } from "react-router-dom";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import AppContext from "../../contexts/AppContext";
import HomeIcon from "@mui/icons-material/Home";
// @ts-ignore
import logo from "../img/logo.png";
import { Card } from "@mui/material";

/**
 * Pagina principal con botones que redirigen a las listas de clientes o presupuestos
 * @name Home
 * @Component
 */
export default function Home() {
  const { setGlobal } = useContext(AppContext);
  const navigate = useNavigate();
  useEffect(() => {
    setGlobal((prev) => ({
      ...prev,
      pageTitle: (
        <>
          <HomeIcon fontSize="large" />
          <div className="titleText">Inicio</div>
        </>
      ),
    }));
  }, [setGlobal]);

  return (
    // <div className="homePage">
    // <Card className="cardTrans4">
      <div className="homePage">

        <img className="logoHome" alt="" src={logo} />
        <div className="bodyHome">
          <IngCard
            className="card2"
            titulo={"Clientes"}
            contenido={<PersonOutlineOutlinedIcon fontSize="large" />}
            onClick={() => {
              navigate("/clientes");
            }}
          />
          <IngCard
            className="card2"
            contenido={<FactCheckOutlinedIcon fontSize="large" />}
            titulo={"Presupuestos"}
            onClick={() => {
              navigate("/presupuestos");
            }}
          />
        </div>

        {/* <h2>Wellcome to VarillerosApp</h2> */}

      </div>
    // </Card>
    // </div>
  );
}
