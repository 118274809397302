export function getRangoBollos() {
  const var1 = localStorage.getItem("token");
  let token = JSON.parse(var1);
  let accessToken = token ? token["access_token"] : undefined;
  return fetch("/api/rango_bollos/all", {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function getRangoBollo(id) {
  const var1 = localStorage.getItem("token");
  let token = JSON.parse(var1);
  let accessToken = token ? token["access_token"] : undefined;
  return fetch("/api/rango_bollos/" + id, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function postRangoBollo(datos) {
  const var1 = localStorage.getItem("token");
  let token = JSON.parse(var1);
  let accessToken = token ? token["access_token"] : undefined;
  return fetch("/api/rango_bollos/insert", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(datos),
  });
}

export function putRangoBollo(id, datos) {
  const var1 = localStorage.getItem("token");
  let token = JSON.parse(var1);
  let accessToken = token ? token["access_token"] : undefined;
  return fetch("/api/rango_bollos/update/" + id, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(datos),
  });
}

export function deleteRangoBollo(id) {
  const var1 = localStorage.getItem("token");
  let token = JSON.parse(var1);
  let accessToken = token ? token["access_token"] : undefined;
  return fetch("/api/rango_bollos/delete/" + id, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function getRelated(id) {
  const var1 = localStorage.getItem("token");
  let token = JSON.parse(var1);
  let accessToken = token ? token["access_token"] : undefined;
  return fetch("/api/rango_bollos/related/" + id, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}
