import * as React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";

/**
 * Ing Buscador personalizado
 * @name BuscadorIngesis
 * @Component
 * @param {Object} props – parametros de la clase
 * @param {React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>} props.submit - Función onSubmit del buscador
 */
export default function Buscador(props) {
  return (
    <Paper sx={{ p: "2px 4px", display: "flex", alignItems: "center" }}>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Buscar"
        inputProps={{ "aria-label": "search google maps" }}
        onChange={props.submit}
      />
      <SearchIcon />
    </Paper>
  );
}
