import React, { useState, useContext } from "react";
import "./main.scss";
import "./main.css";
import AppBar from "@mui/material/AppBar";
import { IconButton, Toolbar } from "@mui/material";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
//Iconos

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import Auth from "../../Auth";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import DirectionsCarFilledOutlinedIcon from "@mui/icons-material/DirectionsCarFilledOutlined";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import CarCrashOutlinedIcon from "@mui/icons-material/CarCrashOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import Tooltip from '@mui/material/Tooltip';

import AppContext from "../../contexts/AppContext";

const Main = ({ children, title }) => {
  //Context
  const { global } = useContext(AppContext);

  const [menuCollapse, setMenuCollapse] = useState(true);
  const navigate = useNavigate();

  return (
    <div id="page">
      <ProSidebar collapsed={menuCollapse} id="menu">
        <SidebarHeader>
          <div>
            {menuCollapse ? (
              <img
                className="logoVarilleros2"
                alt=""
                // @ts-ignore
                src={require("../img/icon.png")}
              />
            ) : (
              <img
                className="logoVarilleros"
                alt=""
                // @ts-ignore
                src={require("../img/logo.png")}
              />
            )}
          </div>
        </SidebarHeader>
        <SidebarContent>
          <Menu>
          <Tooltip title={menuCollapse ? "Inicio" : ""} placement="right" arrow>
            <MenuItem active={window.location.pathname === "/"} icon={<HomeOutlinedIcon />}>
              Inicio
              <Link to="/" />
            </MenuItem>
            </Tooltip>
            <Tooltip title={menuCollapse ? "Presupuestos" : ""} placement="right" arrow>
            <MenuItem active={window.location.pathname === "/presupuestos"} icon={<ArticleOutlinedIcon />}>
              Presupuestos
              <Link to="/presupuestos" />
            </MenuItem>
            </Tooltip>
            <Tooltip title={menuCollapse ? "Clientes" : ""} placement="right" arrow>
            <MenuItem active={window.location.pathname === "/clientes"} icon={<PermIdentityOutlinedIcon />}>
              Clientes
              <Link to="/clientes" />
            </MenuItem>
            </Tooltip>
            <Tooltip title={menuCollapse ? "Piezas" : ""} placement="right" arrow>
            <MenuItem active={window.location.pathname === "/piezas"} icon={<SettingsOutlinedIcon />}>
              Piezas
              <Link to="/piezas" />
            </MenuItem>
            </Tooltip>
            <Tooltip title={menuCollapse ? "Rango Bollos" : ""} placement="right" arrow>
            <MenuItem active={window.location.pathname === "/rango_bollos"} icon={<CarCrashOutlinedIcon />}>
              Rango bollos
              <Link to="/rango_bollos" />
            </MenuItem>
            </Tooltip>
            <Tooltip title={menuCollapse ? "Precios" : ""} placement="right" arrow>
            <MenuItem active={window.location.pathname === "/precios"} icon={<PaidOutlinedIcon />}>
              Precios
              <Link to="/precios" />
            </MenuItem>
            </Tooltip>
          </Menu>
        </SidebarContent>
        <SidebarFooter>
          <Menu>
          <Tooltip title={menuCollapse ? "Cerrar Sesión" : ""} placement="right" arrow>
            <MenuItem
              icon={<ExitToAppOutlinedIcon />}
              onClick={() => {
                Auth.logout();
              }}
            >
              Cerrar Sesión
            </MenuItem>
            </Tooltip>
          </Menu>
        </SidebarFooter>
      </ProSidebar>

      <main id="main">
        <div className="navBar">
          <AppBar className="bar" color="secondary">
            <Toolbar className="tool">
              <div className="navBar1">
                <IconButton
                  className="icon"
                  aria-label="Hide Menu"
                  onClick={() => {
                    setMenuCollapse(!menuCollapse);
                  }}
                >
                  {/* {menuCollapse ? <MenuIcon /> : <MoreVertIcon />} */}
                  {menuCollapse ? (
                    <ArrowForwardIosIcon />
                  ) : (
                    <ArrowBackIosIcon />
                  )}
                </IconButton>
                <IconButton
                  className="icon"
                  aria-label="Hide Menu"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>
              </div>
              <div className="navBar2">
                <div className="title">{global.pageTitle}</div>
              </div>
              <div className="navBar3"></div>
              {/* <Menu
                  id="fade-menu"
                  MenuListProps={{
                    'aria-labelledby': 'fade-button',
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  TransitionComponent={Fade}
                >
                  <MenuItem onClick={handleClose}>Profile</MenuItem>
                  <MenuItem onClick={handleClose}>My account</MenuItem>
                  <MenuItem onClick={handleClose}>Logout</MenuItem>
                </Menu> */}
            </Toolbar>
          </AppBar>
        </div>
        <div id="appContent" className="bgImagen2">
          <div id="appContent2">{children}</div>
        </div>
      </main>
    </div>
  );
};
export default Main;
