


export function getEstados() {
    const var1 = localStorage.getItem("token");
    let token = JSON.parse(var1);
    let accessToken = token ? token["access_token"] : undefined;
    return (
        fetch("/api/estado_presupuesto/all", {
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            }
        })
    );
}

