import { useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import IngButton from "../atomos/IngButton";
import IngTFNumeric from "../atomos/IngTFNumeric";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "./Dialog.css";
import IngCheckBox from "../atomos/IngCheckBox";
import IngSelectorBasic from "../atomos/IngSelectorBasic";
import { getPiezas } from "../../services/APIPieza";
import { getRelated, getRangoBollos } from "../../services/APIRangoBollos";
import Auth from "../../Auth";
import {
  getLineaPresupuesto,
  postLineaPresupuesto,
  putLineaPresupuesto,
} from "../../services/APILineaPresupuesto";
import { postPrecioRecomendado } from "../../services/APIPrecio";

/**
 * Dialog para crear o modificar una linea de presupuesto
 * @name LineaPresupuestoDialog
 * @Component
 * @param {Object} props – parametros de la clase
 * @param {boolean} props.open – Estado de apertura del dialog
 * @param {*} props.onClose – Funcion de cerrar el dialog
 * @param {function} props.actualizar – funcion a ejecutar al aceptar
 * @param {number} props.presupuestoId – Id del presupuesto
 * @param {number} props.lineaPresupuestoId - Id de la linea de presupuesto
 */
export default function LineaPresupuestoDialog({
  open,
  onClose,
  actualizar,
  presupuestoId,
  lineaPresupuestoId,
}) {
  const [piezas, setPiezas] = useState([]);
  const [rangos, setRangos] = useState([]);
  const [pieza, setPieza] = useState();

  const schema = yup
    .object({
      pieza: yup
        .object()
        .required("Debes de seleccionar una pieza.")
        .typeError("Debes de seleccionar una pieza."),
      rangoBollos: yup
        .object()
        .required("Debes de seleccionar un rango de bollos.")
        .typeError("Debes de seleccionar un rango de bollos."),
      destapizado: yup.boolean(),
      aluminio: yup.boolean(),
      precio: yup
        .number()
        .typeError("Debes de introducir un precio.")
        .required("Debes de introducir un precio."),
    })
    .required();

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      rangoBollos: null,
      pieza: null,
      destapizado: false,
      aluminio: false,
      precio: "",
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    // GET de Piezas
    open === true &&
      getPiezas()
        .then((res) =>
          res.status === 401 ? Auth.logout() : res.ok ? res.json() : null
        )
        .then((json) => setPiezas(json));
    // GET de Rangos
    // open === true &&
    //   getRangoBollos()
    //     .then((res) =>
    //       res.status === 401 ? Auth.logout() : res.ok ? res.json() : null
    //     )
    //     .then((json) => setRangos(json));
    // GET de LineaPresupuesto
    setRangos([]);
    if (lineaPresupuestoId !== 0 && open === true) {
      getLineaPresupuesto(lineaPresupuestoId)
        .then((res) =>
          res.status === 401 ? Auth.logout() : res.ok ? res.json() : null
        )
        .then((json) => {
          reset(json);
          setPieza(json.pieza.id)
        });
    } else {
      reset({
        rangoBollos: null,
        pieza: null,
        destapizado: false,
        aluminio: false,
        precio: "",
      });
    }
  }, [lineaPresupuestoId, reset, open, actualizar]);

  const onSubmit = (data) => {
    data.piezaId = data.pieza.id;
    data.rangoBollosId = data.rangoBollos.id;
    data.presupuestoId = presupuestoId;

    // const request = {
    //   method: data.id ? "PUT" : "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify(data),
    // };

    data.id
      ? putLineaPresupuesto(data.id, data)
        .then((res) =>
          res.status === 401 ? Auth.logout() : res.ok ? res.json() : null
        )
        .then((json) => {
          if (json) {
            onClose();
            actualizar();
            reset({
              rangoBollos: null,
              pieza: null,
              destapizado: false,
              aluminio: false,
              precio: "",
            });
          }
        })
      : postLineaPresupuesto(data)
        .then((res) =>
          res.status === 401 ? Auth.logout() : res.ok ? res.json() : null
        )
        .then((json) => {
          if (json) {
            onClose();
            actualizar();
            reset({
              rangoBollos: null,
              pieza: null,
              destapizado: false,
              aluminio: false,
              precio: "",
            });
          }
        });
  };

  function buscarPrecio() {
    if (!getValues("pieza") !== null && getValues("rangoBollos") !== null) {
      const datos = {
        piezaId: getValues().pieza.id,
        rangoBollosId: getValues().rangoBollos.id,
        destapizado: getValues().destapizado,
        aluminio: getValues().aluminio,
      };

      postPrecioRecomendado(datos)
        .then((res) =>
          res.status === 401 ? Auth.logout() : res.ok ? res.json() : null
        )
        .then(
          (json) =>
            json.id && setValue("precio", parseFloat(json.precio).toString())
        );
    }
  }
  useEffect(() => {
    getRelated(pieza)
      .then((response) => response.json())
      .then((json) => json && setRangos(json));
  }, [pieza])
  function buscarRangos(option) {
    if (option && option.id) {
      getRelated(option.id)
        .then((response) => response.json())
        .then((json) => json && setRangos(json));
    }
  }

  return (
    <Dialog open={open} onClose={onClose} onSubmit={handleSubmit(onSubmit)}>
      <form className="dialog--precio">
        <div className="dialog--precio--content">
          <div className="dialog--precio--title">
            <h3>
              {lineaPresupuestoId === 0 ? "Crear " : "Modificar "}Reparacion:
            </h3>
          </div>
          <div className="dialog--precio--row">
            <div className="dialog--precio--column">
              <Controller
                name="pieza"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <IngSelectorBasic
                    options={piezas}
                    getOptionLabel={(option) => option.nombre}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    disableClearable={true}
                    className="dialog--precio--selector"
                    label={"Pieza: "}
                    value={value}
                    onChange={(event, option) => {
                      onChange(option);
                      buscarRangos(option);
                      buscarPrecio();
                      setValue("rangoBollos", undefined);
                    }}
                    errors={errors.pieza?.message}
                  />
                )}
              />
              <Controller
                control={control}
                name="destapizado"
                render={({ field: { onChange, value, name } }) => (
                  <IngCheckBox
                    label="Destapizado: "
                    className="dialog--precio--checkbox"
                    onChange={() => {
                      onChange(!getValues(name));
                      buscarPrecio();
                    }}
                    value={value}
                    errors={errors.destapizado?.message}
                  />
                )}
              />
            </div>
            <div className="dialog--precio--column">
              <Controller
                name="rangoBollos"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <IngSelectorBasic
                    options={rangos}
                    getOptionLabel={(option) => option.nombre}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    disableClearable={true}
                    className="dialog--precio--selector"
                    label={"Rango bollos: "}
                    value={value}
                    onChange={(event, option) => {
                      onChange(option);
                      buscarPrecio();
                    }}
                    errors={errors.rangoBollos?.message}
                  />
                )}
              />
              <Controller
                control={control}
                name="aluminio"
                render={({ field: { onChange, value, name } }) => (
                  <IngCheckBox
                    label="Aluminio: "
                    className="dialog--precio--checkbox"
                    onChange={() => {
                      onChange(!getValues(name));
                      buscarPrecio();
                    }}
                    value={value}
                    errors={errors.aluminio?.message}
                  />
                )}
              />
            </div>
            <div className="dialog--precio--column">
              <Controller
                name="precio"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <IngTFNumeric
                    label="Precio: "
                    onChange={onChange}
                    value={value}
                    errors={errors.precio?.message}
                  />
                )}
              />
            </div>
          </div>
        </div>

        <div className="dialog--one--buttons">
          <IngButton
            type="submit"
            variant="contained"
            label="Aceptar"
            color="secondary"
          ></IngButton>
          <IngButton
            onClick={onClose}
            variant="contained"
            color="error"
            label="Cancelar"
          ></IngButton>
        </div>
      </form>
    </Dialog>
  );
}
