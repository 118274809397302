import { TextField } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// import { styled } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3A86A2",
    },
    secondary: {
      main: "#000000",
    },
    error: {
      main: "#FC5454",
    },
    warning: {
      main: "#FDF55E",
    },
    info: {
      main: "#00348e",
    },
    success: {
      main: "#179A54",
    },
  },
});

/**
 * Ing Textfield personalizado
 * @name TextFieldIngesis
 * @Component
 * @param {Object} props – parametros de la clase
 * @param {String} [props.className] - estilo personalizado del TextField
 * @param {React.ReactNode} [props.label] - título del textfield
 * @param {unknown} [props.value] - value del TextField
 * @param {React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>} [props.onChange] - función onChange del TextField
 * @param {boolean} [props.disabled] - boolean que controla la habilitación/deshabilitación del TextField
 * @param {String} [props.placeholder] - placeholder del Textfield
 * @param {String} [props.errors] - texto de error para los validadores
 * @param {boolean} [props.multiline] - controla si el textfield es expandible en función del contenido
 * @param {boolean} [props.labelUp] - Muestra el label siempre arriba
 * @param {boolean} [props.readOnly] - Campo editable
 * @param {"error" | "primary" | "secondary" | "info" | "success" | "warning"} [props.color] - Color
 */
export default function IngTextField(props) {
  // const CssTextField = styled(TextField)({
  //   "& label.Mui-focused": {
  //     color: "black",
  //   },
  //   "& .MuiInput-underline:after": {
  //     borderBottomColor: "black",
  //   },
  //   "& .MuiOutlinedInput-root": {
  //     "& fieldset": {
  //       borderColor: "gray",
  //     },
  //     "&:hover fieldset": {
  //       borderColor: "black",
  //     },
  //     "&.Mui-focused fieldset": {
  //       borderColor: "black",
  //     },
  //   },
  // });

  return (
    <ThemeProvider theme={theme}>
      <div>
        {props.readOnly ? (
          <TextField
            className={props.className || ""}
            label={props.label}
            variant="outlined"
            value={props.value}
            onChange={props.onChange}
            disabled={props.disabled ? props.disabled : false}
            placeholder={props.placeholder}
            InputLabelProps={{ shrink: props.labelUp }}
            InputProps={{
              notched: props.value !== "" && true,
              readOnly: props.readOnly,
            }}
            multiline={props.multiline ? props.multiline : false}
            color={props.color ? props.color : "secondary"}
          />
        ) : (
          <TextField
            className={props.className || ""}
            label={props.label}
            variant="outlined"
            value={props.value}
            onChange={props.onChange}
            disabled={props.disabled ? props.disabled : false}
            placeholder={props.placeholder}
            InputLabelProps={{ shrink: props.labelUp }}
            multiline={props.multiline ? props.multiline : false}
            color={props.color ? props.color : "primary"}
          />
        )}
        <div className="errorYup">{props.errors}</div>
      </div>
    </ThemeProvider>
  );
}
