import { CircularProgress } from "@mui/material";

import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#7D141B",
    },
    secondary: {
      main: "#3A86A2",
    },
    error: {
      main: "#FC5454",
    },
    warning: {
      main: "#FDF55E",
    },
    info: {
      main: "#00348e",
    },
    success: {
      main: "#179A54",
    },
  },
});
/**
 * Componente para substituir las paginas o tablas mientras se cargan los datos de la bd
 * @name Cargando
 * @Component
 */
export default function Cargando() {
  return (
    <ThemeProvider theme={theme}>
      <div className="bodyList">
        <div className="noData">
          <CircularProgress disableShrink /> &nbsp; Cargando...
        </div>
      </div>
    </ThemeProvider>
  );
}
