export default class Auth {
  constructor() {

  }
  static isLogged() {
    const token = localStorage.getItem("token");
    return (
      token ? true : false
    );
  }
  static logout() {
    localStorage.removeItem("token");
    window.location.replace("/");
  }
}