import { useEffect, useState, useContext, useRef } from "react";
import AppContext from "../../contexts/AppContext";
import { useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import IngButton from "../atomos/IngButton";
import Buscador from "../moleculas/Buscador";
import IngTable from "../atomos/IngTable";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import IngTextField from "../atomos/IngTextField";
import IngTFNumeric from "../atomos/IngTFNumeric";
import ArticleIcon from "@mui/icons-material/Article";
import PersonIcon from "@mui/icons-material/Person";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import IngSelectorBasic from "../atomos/IngSelectorBasic";
import ConstructionIcon from "@mui/icons-material/Construction";
import IngDatePicker from "../atomos/IngDatePicker";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import moment from "moment";
import SelectClienteDialog from "../moleculas/SelectClienteDialog";
import SelectVehiculoDialog from "../moleculas/SelectVehiculoDialog";
import LineaPresupuestoDialog from "../moleculas/LineaPresupuestoDialog";
import Auth from "../../Auth";
import { getEstados } from "../../services/APIEstado";
import {
  putPresupuesto,
  getPresupuesto,
  guardarPdf,
} from "../../services/APIPresupuesto";
import { deleteLineaPresupuesto } from "../../services/APILineaPresupuesto";
import { Card } from "@mui/material";
import SelectEmailDialog from "../moleculas/SelectEmailDialog";
import EmailAdministracionDialog from "../moleculas/EmailAdministracionDialog";
import SignaturePad from "react-signature-canvas";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import Cargando from "../atomos/Cargando";
import NoData from "../atomos/NoData";
import TextFieldDescuento from "../atomos/TextFieldDescuento";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import PercentIcon from "@mui/icons-material/Percent";
import EuroIcon from "@mui/icons-material/Euro";
import ContactMailOutlinedIcon from "@mui/icons-material/ContactMailOutlined";
import ConfirmDialog from "../atomos/ConfirmDialog";

/**
 * Formulario de presupuestos
 * @name FormularioPresupuesto
 * @Component
 */
export default function PresupuestoForm() {
  const [loaded, setLoaded] = useState(false);
  const { setGlobal } = useContext(AppContext);
  const [cadenaBusqueda, setCadenaBusqueda] = useState("");
  const params = useParams();
  const [update, setUpdate] = useState(false);
  const [estados, setEstados] = useState([]);
  const [lineasPresupuesto, setLineasPresupuesto] = useState([]);
  const [lineaPresupuestoId, setLineaPresupuestoId] = useState(0);
  const [lineaPresupuestoIdDel, setLineaPresupuestoIdDel] = useState(0);
  const [total, setTotal] = useState(0);
  const [descuento, setDescuento] = useState(0);
  const [candado, setCandado] = useState(true);
  const [obserOcultas, setObserOcultas] = useState(true);
  const [descargaPdf, setDescargaPdf] = useState(true);
  const [tipoDescuento, setTipoDescuento] = useState(true);
  const [descuentoPerc, setDescuentoPerc] = useState(0);
  const [cleanObject, setCleanObject] = useState({});
  const [pdfName, setPdfName] = useState('');

  //firma
  //const [imageURL, setImageURL] = useState(null);
  /**
   * Canvas para firmar el presupuesto
   * @type {React.MutableRefObject} sigCanvas
   */
  const sigCanvas = useRef();
  const clear = () => {
    sigCanvas.current.clear();
  };
  const clear2 = () => {
    sigCanvas.current.clear();
    setValue("firma", "");
    setValue("fechaFirma", null);
  };
  const save = () => {
    //setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
    setValue(
      "firma",
      sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    );
    setValue("fechaFirma", moment(new Date()).format("YYYY-MM-DD"));
    //sigCanvas.current.off();
    updatePresupuesto();
  };
  //fin firma

  const titles = [
    "Pieza",
    "Rango Bollos",
    "Destapizado",
    "Aluminio",
    "Precio (€)",
  ];
  const columns = ["pieza", "rangoBollos", "destapizado", "aluminio", "precio"];

  // VALIDACION DE DATOS

  const schema = yup
    .object({
      id: yup.number(),
      estadoPresupuesto: yup
        .object()
        .required("Debes de seleccionar un estado.")
        .typeError("Debes de seleccionar un estado."),
      cliente: yup
        .object()
        .required("Debes de seleccionar un cliente.")
        .typeError("Debes de seleccionar un cliente."),
      vehiculo: yup.object().typeError("Debes de seleccionar un vehiculo."),
      observaciones: yup
        .string()
        .max(255, "Las observaciones no pueden superar los 255 carácteres."),
      observacionesOcultas: yup
        .string()
        .max(
          255,
          "Las observaciones ocultas no pueden superar los 255 carácteres."
        ),
      descuento: yup.string(),
      iva: yup.string(),
      firma: yup.string(),
      fecha: yup.string().required("Introduce una fecha."),
    })
    .required();

  // CONTROL FROM

  const {
    control,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      id: 0,
      estadoPresupuesto: null,
      codigoAnyo: "",
      codigoNumero: "",
      cliente: null,
      vehiculo: null,
      descuento: "",
      ocultarDescuento: false,
      iva: "",
      fecha: "",
      observaciones: "",
      observacionesOcultas: "",
      firma: "",
      fechaFirma: "",
    },
    resolver: yupResolver(schema),
  });

  /**
   * True si el estado de el presupuesto es facturado, false en cualquier otro caso
   */
  const terminated =
    getValues("estadoPresupuesto") &&
    getValues("estadoPresupuesto").nombre === "Facturado"
      ? true
      : false;

  /**
   * True si el estado de el presupuesto es pendiente, false en cualquier otro caso
   */
  const pendiente =
    getValues("estadoPresupuesto") &&
    getValues("estadoPresupuesto").nombre === "Pendiente"
      ? true
      : false;

  // GUARDAR CAMBIOS

  const onSubmit = (data) => {
    //if (!terminated) {
    data.estadoPresupuestoId = data.estadoPresupuesto.id;
    data.clienteId = data.cliente.id;
    data.vehiculoId = data.vehiculo.id;

    if (!data.iva || data.iva === "") {
      data.iva = 0;
    }

    if (!data.descuento || data.descuento === "") {
      data.descuento = 0;
    }

    putPresupuesto(params.presupuestoId, data).then((res) =>
      res.status === 401 ? Auth.logout() : res.ok ? actualizar() : null
    );
    //}
  };

  // PETICION DE DATOS A LA API

  useEffect(() => {
    getPresupuesto(params.presupuestoId.toString())
      .then((res) =>
        res.status === 401
          ? Auth.logout()
          : res.ok
          ? res.json()
          : setLoaded(true)
      )
      .then((json) => {
        if (json) {
          setLineasPresupuesto(() => {
            let tmpTotal = 0;
            const result = [];
            json.lineasPresupuesto.map((linea) => {
              tmpTotal += parseFloat(linea.precio);
              result.push({
                id: linea.id,
                pieza: linea.pieza.nombre,
                rangoBollos: linea.rangoBollos.nombre,
                destapizado: linea.destapizado ? "Si" : "-",
                aluminio: linea.aluminio ? "Si" : "-",
                precio: parseFloat(linea.precio).toFixed(2),
              });
              return undefined;
            });

            setTotal(tmpTotal);
            let tmpDescuento = tmpTotal - json.descuento;
            setDescuento(tmpDescuento);
            setDescuentoPerc(
              parseFloat(((json.descuento / tmpTotal) * 100).toFixed(2))
            );
            return result;
          });
          reset(json);
          setCleanObject(json);
          clear();
          sigCanvas.current.off();
          sigCanvas.current.fromDataURL(json.firma);
          setPdfName("Presupuesto"+json.codigoAnyo + "_" + json.codigoNumero);
          setGlobal((old) => ({
            ...old,
            pageTitle: (
              <>
                <ArticleIcon fontSize="large" />
                <div className="titleText">
                  Presupuesto: {json.codigoAnyo + "/" + json.codigoNumero}
                </div>
              </>
            ),
          }));
        }
      });
    estados.length < 1 &&
      getEstados()
        .then((res) =>
          res.status === 401 ? Auth.logout() : res.ok ? res.json() : null
        )
        .then((json) => {
          setEstados(json);
        });
    console.log("USE EFFECT");
  }, [
    estados.length,
    params.presupuestoId,
    reset,
    setGlobal,
    update,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getValues("ocultarDescuento"),
  ]);

  // FILTRO DE LA TABLA DE LINEAS

  function filterBuscador(array, searchQuery) {
    if (!searchQuery) return array;
    return array?.filter((o) =>
      Object.keys(o).some(
        (k) =>
          typeof o[k] === "string" &&
          o[k].toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }

  const actualizar = () => {
    setUpdate((old) => !old);
  };

  const submitBuscador = (event) => {
    setCadenaBusqueda(event.target.value);
  };

  // DIALOG CLIENTE

  const [openCliente, setOpenCliente] = useState(false);

  const handleCloseCliente = () => {
    setOpenCliente(false);
  };

  const actualizarCliente = (cliente) => {
    setValue("cliente", cliente);
    onSubmit(getValues());
  };
  // ENVIAR EMAIL
  const [openEmail, setOpenEmail] = useState(false);
  const handleCloseEmail = () => {
    setOpenEmail(false);
  };

  // ENVIAR EMAIL ADMINISTRACION
  const [openEmailAdmin, setOpenEmailAdmin] = useState(false);

  const handleCloseEmailAdmin = () => {
    setOpenEmailAdmin(false);
  };

  // DIALOG VEHICULO

  const [openVehiculo, setOpenVehiculo] = useState(false);

  const handleCloseVehiculo = () => {
    setOpenVehiculo(false);
  };

  const actualizarVehiculo = (vehiculo) => {
    setValue("vehiculo", vehiculo);
    actualizar();
  };

  // DIALOG LINEA PRESUPUESTO:

  const [openLineaPresupuesto, setOpenLineaPresupuesto] = useState(false);

  const handleCloseLineaPresupuesto = () => {
    setLineaPresupuestoId(0);
    setOpenLineaPresupuesto(false);
  };

  const actualizarLineaPresupuesto = () => {
    actualizar();
  };

  // AÑADIR LINEA

  function anadirLineaPresupuesto() {
    setOpenLineaPresupuesto(true);
  }

  function modLineaPresupuesto(id) {
    setLineaPresupuestoId(id);
    setOpenLineaPresupuesto(true);
  }

  function delLineaPresupuesto(id) {
    deleteLineaPresupuesto(id)
      .then((res) =>
        res.status === 401 ? Auth.logout() : res.ok ? res.json() : null
      )
      .then((json) => {
        json.id ? actualizar() : console.log(json.message);
      });
  }

  // UPDATE DESCUENTO, IVA E OBSERVACIONES

  function updatePresupuesto() {
    onSubmit(getValues());
  }

  /**
   * Metodo para actualizar el presupuesto, solo si ha cambiado el parametro que le introducimos
   * @param {*} key Key del parametro del objeto Presupuesto
   */
  function updatePresupuestoIfDirty(key) {
    if (cleanObject[key] !== getValues(key)) {
      onSubmit(getValues());
    }
  }

  /**
   * Metodo que genera y descarga el pdf
   */
  function descargarPdf() {
    guardarPdf(params.presupuestoId)
      .then((res) => {
        if (res.status === 401) {
          Auth.logout();
        } else if (res.ok) {
          return res.blob();
        } else {
          return undefined;
        }
      })
      .then((blob) => {
        if (blob) {
          setDescargaPdf(true);
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            pdfName + ".pdf"
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      });
  }

  /**
   * Metodo que oculta el descuento
   */
  function ocultarDescuento() {
    setValue("ocultarDescuento", true);
    updatePresupuesto();
  }

  /**
   * Se ocupa de hacer un update si el descuento(%) ha cambiado
   */
  function changeDescuento() {
    let tmp = (total * descuentoPerc) / 100;
    if (parseFloat(getValues("descuento")).toFixed(2) !== tmp.toFixed(2)) {
      setValue("descuento", tmp.toString());
      updatePresupuestoIfDirty("descuento");
    }
  }

  // CONFIRM ELIMINAR

  const [openConfirmar, setOpenConfirmar] = useState(false);

  const handleCloseConfirmar = () => {
    setOpenConfirmar(false);
  };

  return (
    <div className="bodyList">
      <Card className="cardTrans3">
        <form className="varForm">
          <div className="varForm--textFields">
            <div className="varForm--textFields--content2">
              <Controller
                name="estadoPresupuesto"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <IngSelectorBasic
                    disableClearable={true}
                    options={estados || []}
                    getOptionLabel={(option) => option.nombre || ""}
                    isOptionEqualToValue={(option, value) =>
                      value.id && option.id ? option.id === value.id : true
                    }
                    label={"Estado: "}
                    value={value || { id: 0, nombre: "" }}
                    onChange={(event, option) => {
                      onChange(option);
                      setValue("estadoPresupuesto", option);
                      updatePresupuesto();
                    }}
                    errors={errors.estadoPresupuesto?.message}
                  />
                )}
              />
            </div>

            <div
              className="varForm--textFields--content2"
              onBlur={() => updatePresupuestoIfDirty("fecha")}
            >
              <Controller
                name="fecha"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <IngDatePicker
                    label="Fecha: "
                    value={value || ""}
                    onChange={(value) => {
                      onChange(value);
                    }}
                    readOnly={terminated}
                    errors={errors.fecha?.message}
                  />
                )}
              />
            </div>
          </div>
          <hr />
          <div className="varForm--cliente-vehiculo">
            <div className="varForm--cliente-vehiculo--content">
              <div className="varForm--title">
                <PersonIcon fontSize="large" />{" "}
                <div className="titleText">Cliente</div>
              </div>
              <div className="varForm--textFields">
                <div className="varForm--textFields--content">
                  <IngTextField
                    label="Nombre: "
                    value={
                      getValues("cliente") ? getValues("cliente").nombre : ""
                    }
                    readOnly={true}
                  />
                  <IngTextField
                    label="Dirección: "
                    value={
                      getValues("cliente") ? getValues("cliente").direccion : ""
                    }
                    readOnly={true}
                  />
                </div>
                <div className="varForm--textFields--content">
                  <IngTFNumeric
                    label="Teléfono: "
                    value={
                      getValues("cliente") ? getValues("cliente").telefono : 0
                    }
                    readOnly={true}
                  />
                  <IngTextField
                    label="Email: "
                    value={
                      getValues("cliente") ? getValues("cliente").email : ""
                    }
                    readOnly={true}
                    errors={errors.cliente?.message}
                  />
                </div>
              </div>
              <div className="varForm--buttons">
                <IngButton
                  onClick={() => setOpenCliente(true)}
                  variant="contained"
                  label="Editar"
                  disabled={terminated || !pendiente}
                  endIcon={<EditOutlinedIcon />}
                />
              </div>
            </div>
            <div className="varForm--cliente-vehiculo--content">
              <div className="varForm--title">
                <DirectionsCarIcon fontSize="large" />{" "}
                <div className="titleText">Vehiculo</div>
              </div>
              <div className="varForm--textFields">
                <div className="varForm--textFields--content">
                  {/*<IngTextField
                    label="Marca: "
                    value={
                      getValues("vehiculo")
                        ? (getValues("vehiculo").modelo &&
                            getValues().vehiculo.modelo.marca) ||
                          ""
                        : ""
                    }
                    labelUp={
                      getValues("vehiculo")
                        ? getValues("vehiculo").modelo && true
                        : undefined
                    }
                    readOnly={true}
                  />*/}
                  <IngTextField
                    label="Marca: "
                    value={
                      getValues("vehiculo") ? getValues("vehiculo").marca : ""
                    }
                    labelUp={
                      getValues("vehiculo")
                        ? getValues("vehiculo").marca !== ""
                        : undefined
                    }
                    readOnly={true}
                  />

                  <IngTextField
                    label="Matricula: "
                    value={
                      getValues("vehiculo")
                        ? getValues("vehiculo").matricula
                        : ""
                    }
                    labelUp={
                      getValues("vehiculo")
                        ? getValues("vehiculo").matricula !== ""
                        : undefined
                    }
                    readOnly={true}
                  />
                </div>
                <div className="varForm--textFields--content">
                  {/*<IngTextField
                    label="Modelo: "
                    value={
                      getValues("vehiculo")
                        ? (getValues("vehiculo").modelo &&
                            getValues().vehiculo.modelo.nombre) ||
                          ""
                        : ""
                    }
                    labelUp={
                      getValues("vehiculo")
                        ? getValues("vehiculo").modelo && true
                        : undefined
                    }
                    readOnly={true}
                  />*/}
                  <IngTextField
                    label="Modelo: "
                    value={
                      getValues("vehiculo") ? getValues("vehiculo").modelo : ""
                    }
                    labelUp={
                      getValues("vehiculo")
                        ? getValues("vehiculo").modelo !== ""
                        : undefined
                    }
                    readOnly={true}
                  />

                  <IngTextField
                    label="Color: "
                    value={
                      getValues("vehiculo") ? getValues("vehiculo").color : ""
                    }
                    labelUp={
                      getValues("vehiculo")
                        ? getValues("vehiculo").color !== ""
                        : undefined
                    }
                    readOnly={true}
                    errors={errors.vehiculo?.message}
                  />
                </div>
              </div>
              <div className="varForm--buttons">
                <IngButton
                  onClick={() => setOpenVehiculo(true)}
                  variant="contained"
                  label="Editar"
                  disabled={terminated}
                  endIcon={<EditOutlinedIcon />}
                />
              </div>
            </div>
          </div>
          <hr />
          <div className="varForm--title">
            <ConstructionIcon fontSize="large" />{" "}
            <div className="titleText">Reparaciones</div>
          </div>
          <div className="buscadorCards">
            <div className="buscador2">
              <Buscador submit={submitBuscador} />
            </div>

            <IngButton
              onClick={() => anadirLineaPresupuesto()}
              variant="contained"
              label="Añadir"
              disabled={terminated}
              endIcon={<AddCircleOutlineOutlinedIcon />}
            ></IngButton>
          </div>

          {lineasPresupuesto ? (
            <>
              <IngTable
                data={filterBuscador(lineasPresupuesto, cadenaBusqueda)}
                titulos={titles}
                id="id"
                columnas={columns}
                onClickEdit={(id) => modLineaPresupuesto(id)}
                onClickDelete={(id) => {
                  setLineaPresupuestoIdDel(id);
                  setOpenConfirmar(true);
                }}
                altura={60}
                buscador={false}
                crud={terminated ? undefined : "crud"}
                pagination={false}
              />
            </>
          ) : loaded ? (
            <NoData text="⚠️ No se ha podido conectar con la base de datos." />
          ) : (
            <Cargando />
          )}
          <div className="varForm--calculos">
            <div className="varForm--calculos--line">
              {getValues("ocultarDescuento") && (
                <IconButton
                  disabled={terminated}
                  onClick={() => {
                    setValue("ocultarDescuento", false);
                    updatePresupuesto();
                  }}
                >
                  <VisibilityOutlinedIcon fontSize="small" />
                </IconButton>
              )}
              <div className="varForm--textFields--content2">
                <IngTFNumeric
                  label="Total bruto (€): "
                  value={total.toFixed(2) || 0}
                  labelUp={true}
                  readOnly={true}
                />
              </div>
            </div>
            {!getValues("ocultarDescuento") && (
              <div className="varForm--calculos--line">
                <IngButton
                  onClick={() => setTipoDescuento((old) => !old)}
                  variant="contained"
                  label="Cambiar a"
                  disabled={total === 0}
                  endIcon={
                    tipoDescuento ? (
                      <PercentIcon fontSize="small" />
                    ) : (
                      <EuroIcon fontSize="small" />
                    )
                  }
                />
                {tipoDescuento ? (
                  <div
                    className="varForm--textFields--content2"
                    onBlur={() => updatePresupuestoIfDirty("descuento")}
                  >
                    <Controller
                      name="descuento"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextFieldDescuento
                          label={"Descuento (€): "}
                          onChange={onChange}
                          value={value || ""}
                          readOnly={terminated}
                          disabled={total === 0}
                          action={ocultarDescuento}
                          errors={errors.descuento?.message}
                          type="number"
                        />
                      )}
                    />
                  </div>
                ) : (
                  <div
                    className="varForm--textFields--content2"
                    onBlur={() => changeDescuento()}
                  >
                    <TextFieldDescuento
                      label={"Descuento (%): "}
                      onChange={(event) => {
                        setDescuentoPerc(parseFloat(event.target.value));
                      }}
                      value={descuentoPerc || ""}
                      disabled={total === 0}
                      readOnly={terminated}
                      action={ocultarDescuento}
                      errors={errors.descuento?.message}
                      type="number"
                    />
                  </div>
                )}
                <div className="varForm--textFields--content2">
                  <IngTFNumeric
                    label="Base Imponible (€): "
                    value={descuento.toFixed(2) || 0}
                    labelUp={true}
                    readOnly={true}
                  />
                </div>
              </div>
            )}
            <div className="varForm--calculos--line">
              <div
                className="varForm--textFields--content2"
                onBlur={() => updatePresupuestoIfDirty("iva")}
              >
                <Controller
                  name="iva"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <IngTFNumeric
                      label="IVA (%): "
                      onChange={onChange}
                      value={value || ""}
                      readOnly={terminated}
                      errors={errors.iva?.message}
                    />
                  )}
                />
              </div>
              {getValues("iva") && (
                <div className="varForm--textFields--content2">
                  <IngTFNumeric
                    label="Total (€): "
                    value={
                      getValues().ocultarDescuento
                        ? (
                            total +
                            (total * parseInt(getValues().iva)) / 100
                          ).toFixed(2)
                        : (
                            descuento +
                            (descuento * parseInt(getValues().iva)) / 100
                          ).toFixed(2)
                    }
                    labelUp={true}
                    readOnly={true}
                  />
                </div>
              )}
            </div>
          </div>
          <div
            className="varForm--textFields--observaciones"
            onBlur={() => updatePresupuestoIfDirty("observaciones")}
          >
            <Controller
              name="observaciones"
              control={control}
              render={({ field: { onChange, value } }) => (
                <IngTextField
                  label="Observaciones: "
                  onChange={onChange}
                  value={value || ""}
                  multiline={true}
                  errors={errors.observaciones?.message}
                  readOnly={terminated}
                />
              )}
            />
          </div>
          {/* <div className="varForm--textFields2">
            <div className="varForm--textFields--content2"> */}
          <div className="varForm--textFields2">
            <div className="firma">
              <SignaturePad
                ref={sigCanvas}
                canvasProps={{
                  className: "signatureCanvas",
                }}
              />
              <div className="firma--buttons">
                {candado ? (
                  <IconButton
                    className=""
                    aria-label="Hide Menu"
                    onClick={() => {
                      setCandado(!candado);
                      sigCanvas.current.on();
                    }}
                  >
                    <LockOutlinedIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    className=""
                    aria-label="Hide Menu"
                    onClick={() => {
                      setCandado(!candado);
                      sigCanvas.current.off();
                    }}
                  >
                    <LockOpenOutlinedIcon />
                  </IconButton>
                )}
                {candado ? (
                  <></>
                ) : (
                  <>
                    <IconButton
                      className=""
                      aria-label="Hide Menu"
                      onClick={() => {
                        save();
                      }}
                    >
                      <CheckCircleOutlinedIcon />
                    </IconButton>
                    <IconButton
                      className=""
                      aria-label="Hide Menu"
                      onClick={() => {
                        clear2();
                      }}
                    >
                      <CancelOutlinedIcon />
                    </IconButton>
                  </>
                )}
              </div>
            </div>

            <div className="varForm--firma-fechaF">
              {obserOcultas && (
                <div>
                  <IconButton onClick={() => setObserOcultas((old) => !old)}>
                    <VisibilityOutlinedIcon />
                  </IconButton>
                </div>
              )}
              <Controller
                name="fechaFirma"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <IngDatePicker
                    className="fechaFirma"
                    label="Fecha firma: "
                    onChange={onChange}
                    value={value ? value : ""}
                    errors={errors.fechaFirma?.message}
                    readOnly={true}
                  />
                )}
              />
            </div>
          </div>
          {!obserOcultas && (
            <div
              className="varForm--textFields--observaciones"
              onBlur={() => updatePresupuestoIfDirty("observacionesOcultas")}
            >
              <Controller
                name="observacionesOcultas"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextFieldDescuento
                    label="Observaciones ocultas: "
                    onChange={onChange}
                    value={value || ""}
                    multiline={true}
                    errors={errors.observacionesOcultas?.message}
                    action={() => setObserOcultas((old) => !old)}
                  />
                )}
              />
            </div>
          )}
          <p className="varForm--txt">
            Este presupuesto tiene una validez de 12 días.
          </p>

          <div className="varForm--buttons">
            {descargaPdf ? (
              <>
                <IngButton
                  onClick={() => {
                    setDescargaPdf(false);
                    descargarPdf();
                  }}
                  variant="contained"
                  label="Descargar PDF"
                  endIcon={<FileDownloadOutlinedIcon />}
                />
                <IngButton
                  onClick={() => setOpenEmail(true)}
                  variant="contained"
                  label="Enviar email"
                  endIcon={<MailOutlineOutlinedIcon />}
                />
                <IngButton
                  onClick={() => setOpenEmailAdmin(true)}
                  variant="contained"
                  label="Enviar a Administración"
                  endIcon={<ContactMailOutlinedIcon />}
                />
              </>
            ) : (
              <Cargando />
            )}
          </div>
        </form>
        {getValues("cliente") && (
          <SelectClienteDialog
            open={openCliente}
            onClose={handleCloseCliente}
            actualizar={actualizarCliente}
            clienteId={getValues("cliente").id}
          />
        )}
        {getValues("vehiculo") && (
          <SelectVehiculoDialog
            open={openVehiculo}
            onClose={handleCloseVehiculo}
            actualizar={actualizarVehiculo}
            vehiculoId={getValues("vehiculo") && getValues("vehiculo").id}
          />
        )}
        <LineaPresupuestoDialog
          open={openLineaPresupuesto}
          onClose={handleCloseLineaPresupuesto}
          actualizar={actualizarLineaPresupuesto}
          presupuestoId={getValues("id")}
          lineaPresupuestoId={lineaPresupuestoId}
        />
        <SelectEmailDialog
          open={openEmail}
          onClose={handleCloseEmail}
          presupuestoId={getValues("id")}
          clienteId={getValues("cliente") && getValues("cliente").id}
          actualizar={actualizar}
        />
        <EmailAdministracionDialog
          open={openEmailAdmin}
          onClose={handleCloseEmailAdmin}
          presupuestoId={getValues("id")}
          actualizar={actualizar}
        />
      </Card>
      <ConfirmDialog
        aceptar={delLineaPresupuesto}
        open={openConfirmar}
        onClose={handleCloseConfirmar}
        title={"Eliminar rango de bollos"}
        id={lineaPresupuestoIdDel}
        text={"¿Seguro que desea eliminar el rango?"}
      />
    </div>
  );
}
