import { Dialog } from "@mui/material";
import IngButton from "../atomos/IngButton";

/**
 * Dialog de confirmacion
 * @Component
 * @name ConfirmDialog
 * @param {Object} props – parametros de la clase
 * @param {boolean} props.open – parametros de la clase
 * @param {*} props.onClose – parametros de la clase
 * @param {function} props.aceptar – parametros de la clase
 * @param {String} props.title – parametros de la clase
 * @param {String} props.text – parametros de la clase
 * @param {number} props.id – parametros de la clase
 */
export default function ConfirmDialog(props) {
  const { open, onClose, aceptar, title, text, id } = props;
  return (
    <Dialog open={open} onClose={onClose}>
      <div className="dialog--one">
        <div className="dialog--one--column">
          <div className="dialog--one--title">
            <h3>{title}:</h3>
          </div>
          {text}
        </div>
        <div className="dialog--one--buttons">
          <IngButton
            onClick={() => {
              aceptar(id && id);
              onClose();
            }}
            variant="contained"
            label="Aceptar"
            color="secondary"
          />
          <IngButton
            onClick={onClose}
            variant="contained"
            color="error"
            label="Cancelar"
          ></IngButton>
        </div>
      </div>
    </Dialog>
  );
}
