import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import FactoryIcon from "@mui/icons-material/Factory";
import ApartmentIcon from "@mui/icons-material/Apartment";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router";
import DeleteIcon from "@mui/icons-material/Delete";

import "./CardCliente.css";
import { useState } from "react";
import ConfirmDialog from "../atomos/ConfirmDialog";

/**
 * Card para la lista de clientes
 * @name CardCliente
 * @Component
 * @param {Object} props – parametros de la clase
 * @param {Object} props.cliente - Objeto cliente
 * @param {function} props.eliminar - función para eliminar el cliente
 */
export default function CardCliente(props) {
  const navigate = useNavigate();
  const { cliente, eliminar } = props;
  const { id, nombre, empresa, telefono, email, poblacion } = cliente;

  // CONFIRMACION

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  function delPres() {
    eliminar(id);
  }

  return (
    <div className="card">
      <div className="card--title">
        <PersonIcon fontSize="large" />
        {nombre}
      </div>
      <hr />
      <div className="card--infoContainer">
        <div className="card--content">
          <span className="card--label">
            <PhoneIcon fontSize="small" />
            &nbsp; Teléfono:
          </span>
          &nbsp;
          {telefono || "No especificado"}
        </div>
        <div className="card--content">
          <span className="card--label">
            <EmailIcon fontSize="small" /> &nbsp; Email:
          </span>
          &nbsp;
          {email || "No especificado"}
        </div>
        <div className="card--content">
          <span className="card--label">
            <FactoryIcon fontSize="small" />
            &nbsp; Empresa:
          </span>
          &nbsp;
          {empresa || "No especificado"}
        </div>
        <div className="card--content">
          <span className="card--label">
            <ApartmentIcon fontSize="small" />
            &nbsp; Población:
          </span>
          &nbsp;
          {poblacion || "No especificado"}
        </div>
      </div>
      <hr />
      <div className="card--button">
        <div className="card--label" onClick={() => navigate("/cliente/" + id)}>
          DETALLES &nbsp; <ArrowForwardIcon fontSize="small" />
        </div>
        <div className="card--label" onClick={() => setOpen(true)}>
          <DeleteIcon fontSize="small" /> ELIMINAR
        </div>
      </div>
      <ConfirmDialog
        aceptar={delPres}
        open={open}
        onClose={handleClose}
        title={"Eliminar cliente"}
        text={"¿Seguro que desea eliminar a " + cliente.nombre + "?"}
        id={undefined}
      />
    </div>
  );
}
