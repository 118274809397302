import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

/**
 * Ing Selector Básico personalizado
 * @name SelectorBasicoIngesis
 * @Component
 * @param {Object} props – parametros de la clase
 * @param {String} [props.className] - estilo personalizado del selector
 * @param {Array} props.options - array de opciones
 * @param {*} props.getOptionLabel - parámetro que sirva de título de la opción del array de opciones
 * @param {any} [props.value] - value asociado al selector
 * @param {*} props.onChange - función onChange del selector
 * @param {*} [props.isOptionEqualToValue] - función para comprobar si la opción y el valor hacen match para precarga de selectores
 * @param {String} props.label - label para el textField del selector
 * @param {String} [props.errors] - texto de error para validadores
 * @param {boolean} [props.readOnly] - Campo editable
 * @param {boolean} [props.disableClearable] - Quita el boton clear del selector
 */
export default function IngSelectorBasic(props) {
  return (
    <div>
      <Autocomplete
        className={props.className ? props.className : ""}
        disablePortal={false}
        id="combo-box-demo"
        options={props.options}
        getOptionLabel={props.getOptionLabel}
        //sx={{ width: 400 }}
        value={props.value}
        onChange={props.onChange}
        isOptionEqualToValue={props.isOptionEqualToValue}
        renderInput={(params) => <TextField {...params} label={props.label} />}
        disableClearable={props.disableClearable}
        readOnly={props.readOnly}
        disabledItemsFocusable={props.readOnly}
      />
      <div className="errorYup">{props.errors}</div>
    </div>
  );
}
