


export function getVehiculos() {
    const var1 = localStorage.getItem("token");
    let token = JSON.parse(var1);
    let accessToken = token ? token["access_token"] : undefined;
    return (
        fetch("/api/vehiculo/all", {
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            }
        })
    );
}

export function getVehiculo(id) {
    const var1 = localStorage.getItem("token");
    let token = JSON.parse(var1);
    let accessToken = token ? token["access_token"] : undefined;
    return (
        fetch("/api/vehiculo/" + id, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            }
        })
    );
}

export function postVehiculo(datos) {
    const var1 = localStorage.getItem("token");
    let token = JSON.parse(var1);
    let accessToken = token ? token["access_token"] : undefined;
    return (
        fetch("/api/vehiculo/insert", {
            method: "POST",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(datos),
        })
    );
}

export function putVehiculo(id, datos) {
    const var1 = localStorage.getItem("token");
    let token = JSON.parse(var1);
    let accessToken = token ? token["access_token"] : undefined;
    return (
        fetch("/api/vehiculo/update/" + id, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(datos),
        })
    );
}

export function deleteVehiculo(id) {
    const var1 = localStorage.getItem("token");
    let token = JSON.parse(var1);
    let accessToken = token ? token["access_token"] : undefined;
    return (
        fetch("/api/vehiculo/delete/" + id, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            }
        })
    );
}
