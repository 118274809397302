export function getPresupuestos() {
  const var1 = localStorage.getItem("token");
  let token = JSON.parse(var1);
  let accessToken = token ? token["access_token"] : undefined;
  return fetch("/api/presupuesto/all", {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function getPresupuestosCliente(id) {
  const var1 = localStorage.getItem("token");
  let token = JSON.parse(var1);
  let accessToken = token ? token["access_token"] : undefined;
  return fetch("/api/presupuesto/cliente/" + id, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function getPresupuesto(id) {
  const var1 = localStorage.getItem("token");
  let token = JSON.parse(var1);
  let accessToken = token ? token["access_token"] : undefined;
  return fetch("/api/presupuesto/" + id, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function enviarPdf(id, datos) {
  const var1 = localStorage.getItem("token");
  let token = JSON.parse(var1);
  let accessToken = token ? token["access_token"] : undefined;
  return fetch("/api/presupuesto/send/" + id, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(datos),
  });
}

export function enviarPdfAdmin(id) {
  const var1 = localStorage.getItem("token");
  let token = JSON.parse(var1);
  let accessToken = token ? token["access_token"] : undefined;
  return fetch("/api/presupuesto/send_administracion/" + id, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function guardarPdf(id) {
  const var1 = localStorage.getItem("token");
  let token = JSON.parse(var1);
  let accessToken = token ? token["access_token"] : undefined;
  return fetch("/api/presupuesto/pdf/" + id, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function postPresupuesto(datos) {
  const var1 = localStorage.getItem("token");
  let token = JSON.parse(var1);
  let accessToken = token ? token["access_token"] : undefined;
  return fetch("/api/presupuesto/insert", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(datos),
  });
}

export function putPresupuesto(id, datos) {
  const var1 = localStorage.getItem("token");
  let token = JSON.parse(var1);
  let accessToken = token ? token["access_token"] : undefined;
  return fetch("/api/presupuesto/update/" + id, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(datos),
  });
}

export function deletePresupuesto(id) {
  const var1 = localStorage.getItem("token");
  let token = JSON.parse(var1);
  let accessToken = token ? token["access_token"] : undefined;
  return fetch("/api/presupuesto/delete/" + id, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}
