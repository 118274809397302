import * as React from "react";
import { TextField } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3A86A2",
    },
    secondary: {
      main: "#000000",
    },
    error: {
      main: "#FC5454",
    },
    warning: {
      main: "#FDF55E",
    },
    info: {
      main: "#00348e",
    },
    success: {
      main: "#179A54",
    },
  },
});

/**
 * Ing DatePicker personalizado
 * @name DatePickerIngesis
 * @Component
 * @param {Object} props – parametros de la clase
 * @param {String} [props.className] - estilo personalizado del Datepicker
 * @param {String} [props.label] - texto del DatePicker
 * @param {unknown} [props.value] - value del DatePicker
 * @param {React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>} [props.onChange] - función onChange del DatePicker
 * @param {boolean} [props.disabled] - boolean que controla la habilitación/deshabilitación del DatePicker
 * @param {String} [props.errors] - texto de los errores para los validadores de formularios
 * @param {boolean} [props.readOnly] - Campo editable
 */
export default function IngDatePicker(props) {
  return (
    <ThemeProvider theme={theme}>
      <div>
        <TextField
          className={props.className ? props.className : ""}
          // id="custom-css-outlined-input"
          label={props.label}
          variant="outlined"
          value={props.value}
          onChange={props.onChange}
          disabled={props.disabled ? props.disabled : undefined}
          type="date"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            readOnly: props.readOnly,
          }}
          color={props.readOnly ? "secondary" : "primary"}
        />
        <div className="errorYup">{props.errors}</div>
      </div>
    </ThemeProvider>
  );
}
