import React, { useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import IngButton from "../atomos/IngButton";
import IngTextField from "../atomos/IngTextField";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "./Dialog.css";
import {
  getRangoBollo,
  postRangoBollo,
  putRangoBollo,
} from "../../services/APIRangoBollos";
import Auth from "../../Auth";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

/**
 * Dialog para crear o modificar rangos de bollos
 * @name RangoBollosDialog
 * @Component
 * @param {Object} props – parametros de la clase
 * @param {boolean} props.open – Estado de apertura del dialog
 * @param {*} props.onClose – Funcion de cerrar el dialog
 * @param {function} props.actualizar – funcion a ejecutar al aceptar
 * @param {number} props.id – Id del rango de bollos
 */
export default function RangoBollosDialog({ open, onClose, actualizar, id }) {
  //alert
  const [bad, setBad] = useState(false);
  const [ok, setOk] = useState(false);
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const [openA, setOpenA] = React.useState(false);

  const handleCloseA = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenA(false);
  };
  //finAlert
  const schema = yup
    .object({
      nombre: yup.string().required("Debes de introducir un nombre."),
    })
    .required();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      nombre: "",
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (id !== 0 && open) {
      getRangoBollo(id)
        .then((res) =>
          res.status === 401 ? Auth.logout() : res.ok ? res.json() : null
        )
        .then((json) => reset(json));
    } else {
      reset({ nombre: "" });
    }
  }, [id, reset, onClose, open]);

  const onSubmit = (data) => {
    data.id
      ? putRangoBollo(data.id, data)
          .then((res) =>
            res.status === 401 ? Auth.logout() : res.ok ? res.json() : null
          )
          .then((json) => {
            if (json) {
              setOk(true);
              setBad(false);
              setOpenA(true);
              actualizar();
              onClose();
            } else {
              setOk(false);
              setBad(true);
              setOpenA(true);
            }
          })
      : postRangoBollo(data)
          .then((res) =>
            res.status === 401 ? Auth.logout() : res.ok ? res.json() : null
          )
          .then((json) => {
            if (json) {
              setOk(true);
              setBad(false);
              setOpenA(true);
              actualizar();
              onClose();
            } else {
              setOk(false);
              setBad(true);
              setOpenA(true);
            }
          });
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} onSubmit={handleSubmit(onSubmit)}>
        <form className="dialog--one">
          <div className="dialog--one--column">
            <div className="dialog--one--title">
              <h3>{id === 0 ? "Crear " : "Modificar "}rango:</h3>
            </div>
            <Controller
              name="nombre"
              control={control}
              render={({ field: { onChange, value } }) => (
                <IngTextField
                  label="Nombre: "
                  onChange={onChange}
                  value={value}
                  errors={errors.nombre?.message}
                />
              )}
            />
          </div>
          <div className="dialog--one--buttons">
            <IngButton
              type="submit"
              variant="contained"
              label="Aceptar"
              color="secondary"
            ></IngButton>
            <IngButton
              onClick={onClose}
              variant="contained"
              color="error"
              label="Cancelar"
            ></IngButton>
          </div>
        </form>
      </Dialog>
      {ok ? (
        <Snackbar
          className="snackbar"
          open={openA}
          autoHideDuration={3000}
          onClose={handleCloseA}
        >
          {/* @ts-ignore*/}
          <Alert
            onClose={handleCloseA}
            severity="success"
            sx={{ width: "100%" }}
          >
            Operación realizada con éxito!
          </Alert>
        </Snackbar>
      ) : (
        <></>
      )}
      {bad ? (
        <Snackbar
          className="snackbar"
          open={openA}
          autoHideDuration={3000}
          onClose={handleCloseA}
        >
          {/* @ts-ignore*/}
          <Alert onClose={handleCloseA} severity="error" sx={{ width: "100%" }}>
            Error en la operación!
          </Alert>
        </Snackbar>
      ) : (
        <></>
      )}
    </>
  );
}
