import React, { useState, useEffect, useContext } from "react";
import AppContext from "../../contexts/AppContext";
import IngTable from "../atomos/IngTable";
import Cargando from "../atomos/Cargando";
import Buscador from "../moleculas/Buscador";
import IngButton from "../atomos/IngButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import PiezaDialog from "../moleculas/PiezaDialog";
import Auth from "../../Auth";
import { deletePieza, getPiezas } from "../../services/APIPieza";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import ConfirmDialog from "../atomos/ConfirmDialog";
import NoData from "../atomos/NoData";
import SettingsIcon from "@mui/icons-material/Settings";

/**
 * Pagina de modelos que contiene una tabla de piezas
 * @name PaginaPiezas
 * @Component
 */
export default function Piezas() {
  const [loaded, setLoaded] = useState(false);
  //alert
  const [bad, setBad] = useState(false);
  const [ok, setOk] = useState(false);
  const [uso, setUso] = useState(false);
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const [openA, setOpenA] = React.useState(false);
  const [openB, setOpenB] = React.useState(false);
  const handleCloseA = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenA(false);
    setOpenB(false);
  };
  //finAlert
  const { setGlobal } = useContext(AppContext);
  const [piezas, setPiezas] = useState();
  const [cadenaBusqueda, setCadenaBusqueda] = useState("");
  const titles = ["Nombre"];
  const columns = ["nombre"];
  const [piezaId, setPiezaId] = useState(0);
  const [piezaIdDel, setPiezaIdDel] = useState(0);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    setGlobal((prev) => ({
      ...prev,
      pageTitle: (
        <>
          <SettingsIcon fontSize="large" />
          <div className="titleText">Piezas</div>
        </>
      ),
    }));
    getPiezas()
      .then((res) =>
        res.status === 401
          ? Auth.logout()
          : res.ok
          ? res.json()
          : setLoaded(true)
      )
      .then((json) => setPiezas(json));
  }, [update, setGlobal]);

  function filterBuscador(array, searchQuery) {
    if (!searchQuery) return array;
    return array?.filter((o) =>
      Object.keys(o).some(
        (k) =>
          typeof o[k] === "string" &&
          o[k].toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }

  const submit = (event) => {
    setCadenaBusqueda(event.target.value);
  };

  // DIALOG

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // CRUD

  function anadir() {
    setPiezaId(0);
    handleClickOpen();
  }

  function actualizar() {
    setUpdate((old) => !old);
  }

  function eliminar(id) {
    deletePieza(id).then((res) => {
      if (res.status === 401) {
        Auth.logout();
      } else if (res.status === 402) {
        setOk(false);
        setBad(false);
        setOpenA(false);
        setUso(true);
        setOpenB(true);
      } else if (res.ok) {
        // res.json()
        setOk(true);
        setBad(false);
        setUso(false);
        setOpenB(false);
        setOpenA(true);
        actualizar();
      } else {
        setOk(false);
        setUso(false);
        setOpenB(false);
        setBad(true);
        setOpenA(true);
      }
    });
  }

  // CONFIRMACION ELIMINAR

  const [openConfirmar, setOpenConfirmar] = useState(false);

  const handleCloseConfirmar = () => {
    setOpenConfirmar(false);
  };

  return (
    <div className="bodyList">
      {piezas ? (
        <>
          <div className="buscadorCards">
            <div className="buscador2">
              <Buscador submit={submit} />
            </div>
            <IngButton
              onClick={anadir}
              variant="contained"
              label="Añadir"
              endIcon={<AddCircleOutlineOutlinedIcon />}
            ></IngButton>
          </div>

          <IngTable
            data={filterBuscador(piezas, cadenaBusqueda)}
            titulos={titles}
            id="id"
            columnas={columns}
            onClickEdit={(id) => {
              setPiezaId(id);
              handleClickOpen();
            }}
            onClickDelete={(id) => {
              setPiezaIdDel(id);
              setOpenConfirmar(true);
            }}
            altura={60}
            buscador={false}
            crud="crud"
            pagination={true}
          />

          <PiezaDialog
            open={open}
            onClose={handleClose}
            actualizar={actualizar}
            id={piezaId}
          ></PiezaDialog>
          <ConfirmDialog
            aceptar={eliminar}
            open={openConfirmar}
            onClose={handleCloseConfirmar}
            title={"Eliminar pieza"}
            id={piezaIdDel}
            text={"¿Seguro que desea eliminar la pieza?"}
          />
          {ok ? (
            <Snackbar
              className="snackbar"
              open={openA}
              autoHideDuration={3000}
              onClose={handleCloseA}
            >
              {/* @ts-ignore*/}
              <Alert
                onClose={handleCloseA}
                severity="success"
                sx={{ width: "100%" }}
              >
                Operación realizada con éxito!
              </Alert>
            </Snackbar>
          ) : (
            <></>
          )}
          {bad ? (
            <Snackbar
              className="snackbar"
              open={openA}
              autoHideDuration={3000}
              onClose={handleCloseA}
            >
              {/* @ts-ignore*/}
              <Alert
                onClose={handleCloseA}
                severity="error"
                sx={{ width: "100%" }}
              >
                Error en la operación!
              </Alert>
            </Snackbar>
          ) : (
            <></>
          )}
          {uso ? (
            <Snackbar
              className="snackbar"
              open={openB}
              autoHideDuration={3000}
              onClose={handleCloseA}
            >
              {/* @ts-ignore*/}
              <Alert
                onClose={handleCloseA}
                severity="error"
                sx={{ width: "100%" }}
              >
                Error al borrar, registro en uso!
              </Alert>
            </Snackbar>
          ) : (
            <></>
          )}
        </>
      ) : loaded ? (
        <NoData text="⚠️ No se ha podido conectar con la base de datos." />
      ) : (
        <Cargando />
      )}
    </div>
  );
}
