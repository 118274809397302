import React, { useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import IngButton from "../atomos/IngButton";
import "./Dialog.css";
import IngSelectorBasic from "../atomos/IngSelectorBasic";
import { getClientes, getCliente } from "../../services/APICliente";
import Auth from "../../Auth";

/**
 * Dialog para seleccionar el cliente de un presupuesto
 * @name SelectClienteDialog
 * @Component
 * @param {Object} props – parametros de la clase
 * @param {boolean} props.open – Estado de apertura del dialog
 * @param {*} props.onClose – Funcion de cerrar el dialog
 * @param {function} props.actualizar – funcion a ejecutar al aceptar
 * @param {number} props.clienteId - Id del cliente
 */
export default function SelectClienteDialog({
  open,
  onClose,
  actualizar,
  clienteId,
}) {
  const [clientes, setClientes] = useState([]);
  const [clienteSeleccionado, setClienteSeleccionado] = useState(null);

  useEffect(() => {
    open === true &&
      clienteId &&
      getCliente(clienteId)
        .then((res) =>
          res.status === 401 ? Auth.logout() : res.ok ? res.json() : null
        )
        .then((json) => setClienteSeleccionado(json));
    open === true &&
      getClientes()
        .then((res) =>
          res.status === 401 ? Auth.logout() : res.ok ? res.json() : null
        )
        .then((json) => {
          if (json) setClientes(json);
        });
  }, [clienteId, open]);

  const onChange = (option) => {
    setClienteSeleccionado(option);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <div className="dialog--one">
        <div className="dialog--one--column">
          <div className="dialog--one--title">
            <h3>Seleccionar cliente:</h3>
          </div>
          <IngSelectorBasic
            options={clientes}
            getOptionLabel={(option) => option.nombre}
            label={"Cliente: "}
            value={clienteSeleccionado}
            onChange={(event, option) => {
              onChange(option);
            }}
            disableClearable={true}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </div>
        <div className="dialog--one--buttons">
          <IngButton
            onClick={() => {
              if (clienteSeleccionado.id) {
                actualizar(clienteSeleccionado);
                onClose();
              }
            }}
            variant="contained"
            label="Aceptar"
            color="secondary"
          ></IngButton>
          <IngButton
            onClick={onClose}
            variant="contained"
            color="error"
            label="Cancelar"
          ></IngButton>
        </div>
      </div>
    </Dialog>
  );
}
