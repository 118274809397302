


export function getClientes() {
    const var1 = localStorage.getItem("token");
    let token = JSON.parse(var1);
    let accessToken = token ? token["access_token"] : undefined;
    return (
        fetch("/api/cliente/all", {
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            }
        })
    );
}

export function getCliente(id) {
    const var1 = localStorage.getItem("token");
    let token = JSON.parse(var1);
    let accessToken = token ? token["access_token"] : undefined;
    return (
        fetch("/api/cliente/" + id, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            }
        })
    );
}

export function postCliente(datos) {
    const var1 = localStorage.getItem("token");
    let token = JSON.parse(var1);
    let accessToken = token ? token["access_token"] : undefined;
    return (
        fetch("/api/cliente/insert", {
            method: "POST",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(datos),
        })
    );
}

export function putCliente(id, datos) {
    const var1 = localStorage.getItem("token");
    let token = JSON.parse(var1);
    let accessToken = token ? token["access_token"] : undefined;
    return (
        fetch("/api/cliente/update/" + id, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(datos),
        })
    );
}

export function deleteCliente(id) {
    const var1 = localStorage.getItem("token");
    let token = JSON.parse(var1);
    let accessToken = token ? token["access_token"] : undefined;
    return (
        fetch("/api/cliente/delete/" + id, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            }
        })
    );
}
