import React, { useState, useEffect, useContext } from "react";
import AppContext from "../../contexts/AppContext";
import CardPresupuesto from "../moleculas/CardPresupuesto";
import Buscador from "../moleculas/Buscador";
import IngButton from "../atomos/IngButton";
import ArticleIcon from "@mui/icons-material/Article";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Cargando from "../atomos/Cargando";
import { useNavigate } from "react-router-dom";
import SelectClienteDialog from "../moleculas/SelectClienteDialog";
import moment from "moment";
import Auth from "../../Auth";
import {
  deletePresupuesto,
  getPresupuestos,
  postPresupuesto,
} from "../../services/APIPresupuesto";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import NoData from "../atomos/NoData";

/**
 * Pagina con la lista de cards de presupuestos
 * @name PaginaPresupuestos
 * @Component
 */
export default function Presupuestos() {
  const [loaded, setLoaded] = useState(false);
  //alert
  const [bad, setBad] = useState(false);
  const [ok, setOk] = useState(false);
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const [openA, setOpenA] = React.useState(false);
  const handleCloseA = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenA(false);
  };
  //finAlert
  const { setGlobal } = useContext(AppContext);
  const [presupuestos, setPresupuestos] = useState();
  const [cadenaBusqueda, setCadenaBusqueda] = useState("");
  const navigate = useNavigate();
  const [update, setUpdate] = useState(false);

  /**
   * Metodo para formatear el JSON de presupuestos que llega de la base de datos
   * @param {*} presupuestos Lista de presupuestos de la BD
   * @returns Array
   */
  function formatPresupuestos(presupuestos) {
    presupuestos.map((presupuesto) => {
      presupuesto.codigo =
        presupuesto.codigoAnyo + "/" + presupuesto.codigoNumero;

      presupuesto.cliente = presupuesto.cliente.nombre;

      if (presupuesto.vehiculo) {
        presupuesto.vehiculo =
          presupuesto.vehiculo.matricula || "No seleccionado";
      }

      presupuesto.estadoPresupuesto =
        presupuesto.estadoPresupuesto.nombre || "";

      switch (presupuesto.estadoPresupuesto) {
        case "Aceptado":
          presupuesto.color = "cardPres--title--green";
          break;
        case "Enviado":
          presupuesto.color = "cardPres--title--yellow";
          break;
        case "Facturado":
          presupuesto.color = "cardPres--title--blue";
          break;
        default:
          presupuesto.color = "cardPres--title";
      }
      return undefined;
    });
    return presupuestos;
  }

  useEffect(() => {
    setGlobal((old) => ({
      ...old,
      pageTitle: (
        <>
          <ArticleIcon fontSize="large" />
          <div className="titleText">Presupuestos</div>
        </>
      ),
    }));
    getPresupuestos()
      .then((res) =>
        res.status === 401
          ? Auth.logout()
          : res.ok
          ? res.json()
          : setLoaded(true)
      )
      .then((json) => {
        if (json) setPresupuestos(formatPresupuestos(json.reverse()));
      });
  }, [setGlobal, update]);

  let out = (
    <div className="bodyList">
      {loaded ? (
        <NoData text="⚠️ No se ha podido conectar con la base de datos." />
      ) : (
        <Cargando />
      )}
    </div>
  );

  function filterBuscador(array, searchQuery) {
    if (!searchQuery) return array;
    return array?.filter((o) =>
      Object.keys(o).some(
        (k) =>
          typeof o[k] === "string" &&
          o[k].toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }

  const actualizar = () => {
    setUpdate((old) => !old);
  };

  const submit = (event) => {
    setCadenaBusqueda(event.target.value);
  };

  function anadir() {
    setOpenCliente(true);
  }

  function eliminar(id) {
    deletePresupuesto(id)
      .then((res) =>
        res.status === 401 ? Auth.logout() : res.ok ? res.json() : null
      )
      .then((json) => {
        if (json) {
          setOk(true);
          setBad(false);
          setOpenA(true);
          actualizar();
        } else {
          setOk(false);
          setBad(true);
          setOpenA(true);
        }
      });
  }

  // DIALOG AÑADIR:

  const [openCliente, setOpenCliente] = useState(false);

  const handleCloseCliente = () => {
    setOpenCliente(false);
  };

  const insertarPresupuesto = (cliente) => {
    const presupuesto = {
      clienteId: cliente.id,
      descuento: 0,
      ocultarDescuento: true,
      iva: 21,
      fecha: moment(new Date()).format("YYYY-MM-DD"),
      observaciones: "",
      observacionesOcultas: "",
    };

    postPresupuesto(presupuesto)
      .then((res) =>
        res.status === 401 ? Auth.logout() : res.ok ? res.json() : null
      )
      .then((json) => {
        if (json) {
          setOk(true);
          setBad(false);
          setOpenA(true);
          json.id && navigate("/presupuesto/" + json.id);
        } else {
          setOk(false);
          setBad(true);
          setOpenA(true);
        }
      });
  };

  if (presupuestos) {
    out = (
      <div className="bodyList">
        <div className="buscadorCards">
          <div className="buscador2">
            <Buscador submit={submit} />
          </div>
          <IngButton
            onClick={anadir}
            variant="contained"
            label="Añadir"
            endIcon={<AddCircleOutlineOutlinedIcon />}
          />
          <SelectClienteDialog
            open={openCliente}
            onClose={handleCloseCliente}
            actualizar={insertarPresupuesto}
            clienteId={undefined}
          />
        </div>
        {filterBuscador(presupuestos, cadenaBusqueda).length > 0 ? (
          <div className="cardList">
            {" "}
            {filterBuscador(presupuestos, cadenaBusqueda).map((presupuesto) => (
              <CardPresupuesto
                key={presupuesto.id}
                presupuesto={presupuesto}
                eliminar={eliminar}
              />
            ))}{" "}
          </div>
        ) : (
          <div>
            <NoData text={undefined} />
          </div>
        )}
        {ok ? (
          <Snackbar
            className="snackbar"
            open={openA}
            autoHideDuration={3000}
            onClose={handleCloseA}
          >
            {/* @ts-ignore*/}
            <Alert
              onClose={handleCloseA}
              severity="success"
              sx={{ width: "100%" }}
            >
              Operación realizada con éxito!
            </Alert>
          </Snackbar>
        ) : (
          <></>
        )}
        {bad ? (
          <Snackbar
            className="snackbar"
            open={openA}
            autoHideDuration={3000}
            onClose={handleCloseA}
          >
            {/* @ts-ignore*/}
            <Alert
              onClose={handleCloseA}
              severity="error"
              sx={{ width: "100%" }}
            >
              Error en la operación!
            </Alert>
          </Snackbar>
        ) : (
          <></>
        )}
      </div>
    );
  }

  return out;
}
