import { useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import IngButton from "../atomos/IngButton";
import "./Dialog.css";
import IngSelectorMultiple from "../atomos/IngSelectorMultiple";
import CircularProgress from "@mui/material/CircularProgress";
import IngCheckBox from "../atomos/IngCheckBox";
import { getCliente } from "../../services/APICliente";
import { enviarPdf } from "../../services/APIPresupuesto";
import Auth from "../../Auth";

/**
 * Dialog para seleccionar a quien se ha de enviar el presupuesto
 * @name EnviarEmailDialog
 * @Component
 * @param {Object} props – parametros de la clase
 * @param {boolean} props.open – Estado de apertura del dialog
 * @param {*} props.onClose – Funcion de cerrar el dialog
 * @param {function} props.actualizar – funcion a ejecutar al aceptar
 * @param {number} props.presupuestoId – Id del presupuesto
 * @param {number} props.clienteId - Id del cliente
 */
export default function SelectEmailDialog({
  open,
  onClose,
  actualizar,
  presupuestoId,
  clienteId,
}) {
  const [contactos, setContactos] = useState([]);
  const [contactos2, setContactos2] = useState([]);
  const [enviado, setEnviado] = useState(true);
  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [postIds, setPostIds] = useState([]);
  // const [clienteSeleccionado, setClienteSeleccionado] = useState({
  //   nombre: "",
  // });
  const [checked, setChecked] = useState(false);
  const sendEmail = () => {
    setEnviado(false);
    let datos = {
      cliente: checked,
      clienteId: clienteId,
      contactos: postIds,
    };
    enviarPdf(presupuestoId, datos)
      .then((res) =>
        res.status === 401 ? Auth.logout() : res.ok ? res.json() : null
      )
      .then((json) => {
        if (json) {
          setEnviado(true);
          actualizar();
          onClose();
        } else {
          onClose();
        }
      });
  };
  useEffect(() => {
    setEnviado(true);
    open === true &&
      clienteId &&
      getCliente(clienteId)
        .then((res) =>
          res.status === 401 ? Auth.logout() : res.ok ? res.json() : null
        )
        .then((json) => {
          if (json) {
            setContactos(json.contactos);
            //setClienteSeleccionado(json);
            setNombre(json.nombre);
            setEmail(json.email);
          }
          console.log("EFFECT EMAIL");
        });
    // open === true &&
    //   getClientes()
    //     .then((response) => response.json())
    //     .then((json) => setClientes(json));
    //console.log(contactos);
  }, [clienteId, open]);

  // const onChange = (option) => {
  //   setClienteSeleccionado(option);
  // };
  useEffect(() => {
    if (contactos2.length > 0) {
      let tmp = [];
      contactos2.map((c) => tmp.push(c.id));
      setPostIds(tmp);
    }
  }, [contactos2, postIds]);

  return (
    <Dialog open={open} onClose={onClose}>
      <div className="dialog--one">
        {enviado ? (
          <>
            <div className="dialog--one--column">
              <div className="dialog--one--title">
                <h3>Seleccionar Destinatarios:</h3>
              </div>
              {/* <IngSelectorBasic
              options={contactos}
              getOptionLabel={(option) => option.nombre}
              label={"Cliente: "}
              value={clienteSeleccionado}
              onChange={(event, option) => {
                onChange(option);
              }}
            /> */}
              <IngCheckBox
                label={"Cliente: " + nombre + " - " + email}
                value={checked}
                onChange={() => {
                  setChecked(!checked);
                }}
              />
              <IngSelectorMultiple
                options={contactos}
                getOptionLabel={(option) =>
                  option.nombre + " - " + option.email
                }
                label={"Selecciona Contactos"}
                onChange={(event, value) => setContactos2(value)}
              />
              {/* {contactos.length>0 ? contactos.map((c)=>(
              <IngCheckBox 
                label={c.nombre}
                value={checked}
                onChange ={()=> setChecked(!checked)}
              />
            )) : <>NADA</>}  */}
            </div>

            <div className="dialog--one--buttons">
              <IngButton
                onClick={() => {
                  sendEmail();
                }}
                variant="contained"
                label="Aceptar"
                color="secondary"
              ></IngButton>
              <IngButton
                onClick={onClose}
                variant="contained"
                color="error"
                label="Cancelar"
              ></IngButton>
            </div>
          </>
        ) : (
          <div className="dialog--one">
            <h3>Enviando a los destinatarios...</h3>
            <CircularProgress />
          </div>
        )}
      </div>
    </Dialog>
  );
}
