import React, { useState, useEffect } from "react";
import Buscador from "../moleculas/Buscador";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import { IconButton, TablePagination } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { styled } from "@mui/material/styles";
//header personalizado
export const StyledTableHead = styled(TableHead)`
  & .MuiTableCell-root {
    background-color: #6c6c6c;
    height: 50px;
    color: white;
  }
`;

/**
 * Ing Table personalizado
 * @name TablaIngesis
 * @Component
 * @param {Object} props – parametros de la clase
 * @param {any[]} props.data - array de datos
 * @param {String[]} props.titulos - array de títulos
 * @param {String[]} props.columnas - array de atributos del objeto para las columnas
 * @param {boolean} [props.buscador] - boolean que controla la utilización de buscador incorporado
 * @param {number} [props.altura] - altura fijada de la Tabla
 * @param {"all" | "view" | "crud"} [props.crud] - modo de crud, botones de acción, por defecto ninguno
 * @param {String} props.id - parámetro id de los objetos de data
 * @param {Function} [props.onClickEdit] - función al pulsor el icono editar
 * @param {Function} [props.onClickDelete] - función al pulsor el icono editar
 * @param {Function} [props.onClickView] - función al pulsor el icono editar
 * @param {React.MouseEventHandler<HTMLButtonElement>} [props.onClick] - función onClick del botón
 * @param {boolean} [props.pagination] - boolean que controla la utilización paginado incorporada
 */
export default function IngTable(props) {
  const [data, setData] = useState([]);
  const [titulos, setTitulos] = useState([]);
  const [columnas, setColumnas] = useState([]);
  const [cadenaBusqueda, setCadenaBusqueda] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  //submit Buscador
  const submit = (event) => {
    setCadenaBusqueda(event.target.value);
  };
  //función de filtrado de buscador
  function filterBuscador(array, searchQuery) {
    if (!searchQuery) return array;
    return array?.filter((o) =>
      Object.keys(o).some(
        (k) =>
          typeof o[k] === "string" &&
          o[k].toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }

  //Pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;
  //Fin pagination
  useEffect(() => {
    setData(props.data);
    setTitulos(props.titulos);
    setColumnas(props.columnas);
  }, [props.columnas, props.data, props.titulos]);
  return (
    <div className="">
      <div className="buscadorTabla">
        {props.buscador ? <Buscador submit={submit} /> : null}
      </div>
      <TableContainer
        sx={{
          margin: "auto",
          marginBottom: "10px",
          marginTop: "10px",
          maxHeight: `${props.altura}vh`,
        }}
        component={Paper}
      >
        <Table
          stickyHeader
          size="small"
          sx={{ minWidth: 150 }}
          aria-label="simple table"
        >
          <StyledTableHead>
            <TableRow>
              {titulos?.map((tit, index) => (
                <TableCell
                  key={tit ? tit + "Title" + index : "NoDataTitle" + index}
                  align="left"
                >
                  {tit}
                </TableCell>
              ))}
              {props.crud === "all" ||
              props.crud === "view" ||
              props.crud === "crud" ? (
                <TableCell
                  key={
                    props.crud
                      ? props.crud + "TitleAcciones"
                      : "NoDataTitleAcciones"
                  }
                  align="right"
                >
                  {"Acciones"}
                </TableCell>
              ) : null}
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {/* Datos */}
            {data && data.length > 0 ? (
              filterBuscador(data, cadenaBusqueda)
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow
                    key={
                      row[props.id]
                        ? row[props.id] + index
                        : "NoDataRow" + index
                    }
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    {columnas?.map((col, index) => (
                      <TableCell
                        key={
                          row[props.id] && row[col]
                            ? row[props.id] + row[col] + index
                            : "NoDataCell" + index
                        }
                        align="left"
                      >
                        {row[col] === true
                          ? "True"
                          : row[col] === false
                          ? "False"
                          : row[col]}
                      </TableCell>
                    ))}
                    {props.crud === "all" ? (
                      <TableCell align="right">
                        <IconButton
                          className="icon"
                          aria-label="Hide Menu"
                          onClick={() => {
                            props.onClickView(row[props.id]);
                          }}
                        >
                          <RemoveRedEyeOutlinedIcon />
                        </IconButton>
                        <IconButton
                          className="icon"
                          aria-label="Hide Menu"
                          onClick={() => {
                            props.onClickEdit(row[props.id]);
                          }}
                        >
                          <EditOutlinedIcon />
                        </IconButton>
                        <IconButton
                          className="icon"
                          aria-label="Hide Menu"
                          onClick={() => {
                            props.onClickDelete(row[props.id]);
                          }}
                        >
                          <DeleteOutlineOutlinedIcon />
                        </IconButton>
                      </TableCell>
                    ) : null}
                    {props.crud === "view" ? (
                      <TableCell align="right">
                        <IconButton
                          className="icon"
                          aria-label="Hide Menu"
                          onClick={() => {
                            props.onClickView(row[props.id]);
                          }}
                        >
                          <RemoveRedEyeOutlinedIcon />
                        </IconButton>
                      </TableCell>
                    ) : null}
                    {props.crud === "crud" ? (
                      <TableCell align="right">
                        <IconButton
                          className="icon"
                          aria-label="Hide Menu"
                          onClick={() => {
                            props.onClickEdit(row[props.id]);
                          }}
                        >
                          <EditOutlinedIcon />
                        </IconButton>
                        <IconButton
                          className="icon"
                          aria-label="Hide Menu"
                          onClick={() => {
                            props.onClickDelete(row[props.id]);
                          }}
                        >
                          <DeleteOutlineOutlinedIcon />
                        </IconButton>
                      </TableCell>
                    ) : null}
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <td
                  colSpan={
                    props.titulos
                      ? props.crud
                        ? props.titulos.length + 1
                        : props.titulos.length
                      : 0
                  }
                  style={{ height: "5em", width: "100%", textAlign: "center" }}
                >
                  No se han encontrado datos.
                </td>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {props.pagination ? (
        <TablePagination
          className="pagination"
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : null}
    </div>
  );
}
