import React, { useEffect, useState, useContext } from "react";
import AppContext from "../../contexts/AppContext";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import IngButton from "../atomos/IngButton";
import Cargando from "../atomos/Cargando";
import Buscador from "../moleculas/Buscador";
import IngTable from "../atomos/IngTable";
import ContactsIcon from "@mui/icons-material/Contacts";
import PersonIcon from "@mui/icons-material/Person";
import { Card } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Auth from "../../Auth";
import IngTextField from "../atomos/IngTextField";
import IngTFNumeric from "../atomos/IngTFNumeric";
import ContactoDialog from "../moleculas/ContactoDialog";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import ArticleIcon from "@mui/icons-material/Article";
import { getCliente, postCliente, putCliente } from "../../services/APICliente";
import { deleteContacto } from "../../services/APIContacto";
import { deletePresupuesto } from "../../services/APIPresupuesto";
import {
  getPresupuestosCliente,
  postPresupuesto,
} from "../../services/APIPresupuesto";
import moment from "moment";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ConfirmDialog from "../atomos/ConfirmDialog";

/**
 * Formulario de cliente
 * @name FormularioCliente
 * @Component
 */
export default function ClienteForm() {
  //alert
  const [bad, setBad] = useState(false);
  const [ok, setOk] = useState(false);
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const [openA, setOpenA] = React.useState(false);
  const handleCloseA = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenA(false);
  };
  //finAlert
  const { setGlobal } = useContext(AppContext);
  const navigate = useNavigate();
  const [contactos, setContactos] = useState(null);
  const [presupuestos, setPresupuestos] = useState(null);
  const [cadenaBusquedaContactos, setCadenaBusquedaContactos] = useState("");
  const [cadenaBusquedaPresupuestos, setCadenaBusquedaPresupuestos] =
    useState("");
  const params = useParams();
  const [contactoId, setContactoId] = useState(0);
  const [update, setUpdate] = useState(false);
  const [presupuestoId, setPresupuestoId] = useState();

  const titlesContacto = [
    "Nombre",
    "Cargo",
    "Teléfono",
    "Email",
    "Comentarios",
  ];
  const columnsContacto = [
    "nombre",
    "cargo",
    "telefono",
    "email",
    "comentarios",
  ];

  const titlesPresupuesto = ["Numero", "Vehículo", "Fecha", "Estado"];
  const columnsPresuuesto = ["numero", "vehiculo", "fecha", "estado"];

  /**
   * Metodo para formatear el JSON de presupuestos que llega de la base de datos
   * @param {*} presupuestos Lista de presupuestos de la BD
   * @returns Array
   */
  function formatPresupuestos(presupuestos) {
    presupuestos.map((presupuesto) => {
      presupuesto.numero =
        presupuesto.codigoAnyo + "/" + presupuesto.codigoNumero;

      presupuesto.fecha = moment(presupuesto.fecha).format("DD-MM-YYYY");

      presupuesto.cliente = presupuesto.cliente.nombre;

      if (presupuesto.vehiculo) {
        presupuesto.vehiculo =
          presupuesto.vehiculo.matricula || "No seleccionado";
      }

      presupuesto.estado = presupuesto.estadoPresupuesto.nombre || "";

      switch (presupuesto.estado) {
        case "Aceptado":
          presupuesto.estado = "🟢 " + presupuesto.estado;
          break;
        case "Enviado":
          presupuesto.estado = "🟡 " + presupuesto.estado;
          break;
        case "Facturado":
          presupuesto.estado = "🔵 " + presupuesto.estado;
          break;
        default:
          presupuesto.estado = "⚪ " + presupuesto.estado;
      }
      return undefined;
    });
    return presupuestos;
  }

  // VALIDACION DE DATOS

  const schema = yup
    .object({
      id: yup.number(),
      nombre: yup.string().required("Escribe el nombre del cliente."),
      cif: yup.string(),
      empresa: yup.string(),
      direccion: yup.string(),
      codigoPostal: yup.string(),
      telefono: yup.string(),
      email: yup.string().email("Escribe un email válido."),
      numDeCliente: yup.string(),
      poblacion: yup.string(),
      provincia: yup.string(),
    })
    .required();

  // CONTROL DEL FORMULARIO

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      nombre: "",
      cif: "",
      empresa: "",
      direccion: "",
      codigoPostal: "",
      telefono: "",
      email: "",
      numDeCliente: "",
      poblacion: "",
      provincia: "",
    },
    resolver: yupResolver(schema),
  });

  // PETICION DE DATOS A LA API

  useEffect(() => {
    if (params.clienteId !== "nuevo") {
      // Get cliente
      getCliente(params.clienteId.toString())
        .then((res) =>
          res.status === 401 ? Auth.logout() : res.ok ? res.json() : null
        )
        .then((json) => {
          if (json) {
            setGlobal((old) => ({
              ...old,
              pageTitle: (
                <>
                  <PersonIcon fontSize="large" />
                  <div className="titleText">Cliente: {json.nombre}</div>
                </>
              ),
            }));
            reset(json);
            setContactos(json.contactos);
          }
        });
      // Get PRESUPUESTOS
      getPresupuestosCliente(params.clienteId.toString())
        .then((res) =>
          res.status === 401 ? Auth.logout() : res.ok ? res.json() : null
        )
        .then((json) => {
          if (json) {
            setPresupuestos(formatPresupuestos(json));
          }
        });
    } else {
      setGlobal((old) => ({
        ...old,
        pageTitle: "Nuevo Cliente",
      }));
      setContactos([]);
      setPresupuestos([]);
    }
  }, [params.clienteId, update, setGlobal, reset]);

  // FILTRO DE LA TABLA DE CONTACTOS

  function filterBuscador(array, searchQuery) {
    if (!searchQuery) return array;
    return array?.filter((o) =>
      Object.keys(o).some(
        (k) =>
          typeof o[k] === "string" &&
          o[k].toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }

  const submitBuscadorContactos = (event) => {
    setCadenaBusquedaContactos(event.target.value);
  };

  const submitBuscadorPresupuestos = (event) => {
    setCadenaBusquedaPresupuestos(event.target.value);
  };

  // AÑADIR CONTACTO

  // ENVIAR FORMULARIO CLIENTE

  const onSubmit = (data) => {
    // const request = {
    //   method: params.clienteId !== "nuevo" ? "PUT" : "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify(data),
    // };
    params.clienteId !== "nuevo"
      ? putCliente(params.clienteId, data)
          .then((res) =>
            res.status === 401 ? Auth.logout() : res.ok ? res.json() : null
          )
          .then((json) => {
            if (json) {
              setOk(true);
              setBad(false);
              setOpenA(true);
              actualizar();
            } else {
              setOk(false);
              setBad(true);
              setOpenA(true);
            }
          })
      : postCliente(data)
          .then((res) =>
            res.status === 401 ? Auth.logout() : res.ok ? res.json() : null
          )
          .then((json) => {
            if (params.clienteId === "nuevo") {
              navigate("/cliente/" + json.id);
            }
          });
  };

  // DIALOG CONTACTO

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setContactoId(0);
    setOpen(false);
  };

  // CONFIRMACION INSERTAR PRESUPUESTO

  const [openConfirmarInsertar, setOpenConfirmarInsertar] = useState(false);

  const handleCloseConfirmarInsertar = () => {
    setOpenConfirmarInsertar(false);
  };

  // CONFIRMACION ELIMINAR PRESUPUESTO

  const [
    openConfirmarEliminarPresupuesto,
    setOpenConfirmarEliminarPresupuesto,
  ] = useState(false);

  const handleCloseConfirmarEliminarPresupuesto = () => {
    setOpenConfirmarEliminarPresupuesto(false);
  };

  // CONFIRMACION ELIMINAR CONTACTO

  const [openConfirmarEliminarContacto, setOpenConfirmarEliminarContacto] =
    useState(false);

  const handleCloseConfirmarEliminarContacto = () => {
    setOpenConfirmarEliminarContacto(false);
  };

  // CRUD Contactos

  function anadir() {
    handleClickOpen();
  }

  function actualizar() {
    setUpdate((old) => !old);
  }

  function eliminar(id) {
    deleteContacto(id)
      .then((res) =>
        res.status === 401 ? Auth.logout() : res.ok ? res.json() : null
      )
      .then((json) => {
        if (json) {
          actualizar();
        }
      });
  }

  function eliminarPresupuesto(id) {
    deletePresupuesto(id)
      .then((res) =>
        res.status === 401 ? Auth.logout() : res.ok ? res.json() : null
      )
      .then((json) => {
        json.id ? actualizar() : console.log(json.message);
      });
  }

  // CRUD Presupuestos

  /**
   * Funcion para insertar un nuevo presupuesto del cliente actual
   */
  const insertarPresupuesto = () => {
    const presupuesto = {
      clienteId: params.clienteId,
      descuento: 0,
      ocultarDescuento: true,
      iva: 21,
      fecha: moment(new Date()).format("YYYY-MM-DD"),
      observaciones: "",
      observacionesOcultas: "",
    };

    postPresupuesto(presupuesto)
      .then((response) => response.json())
      .then((json) => json.id && navigate("/presupuesto/" + json.id));
  };

  return (
    <div className="bodyList">
      <Card className="cardTrans3">
        <form className="varForm">
          <div className="varForm--textFields">
            <div className="varForm--textFields--content">
              <Controller
                name="nombre"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <IngTextField
                    label="Nombre: "
                    onChange={onChange}
                    value={value}
                    errors={errors.nombre?.message}
                  />
                )}
              />
              <Controller
                name="cif"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <IngTextField
                    label="CIF: "
                    onChange={onChange}
                    value={value}
                    errors={errors.cif?.message}
                  />
                )}
              />
              <Controller
                name="empresa"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <IngTextField
                    label="Empresa: "
                    onChange={onChange}
                    value={value}
                    errors={errors.empresa?.message}
                  />
                )}
              />
              <Controller
                name="direccion"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <IngTextField
                    label="Dirección: "
                    onChange={onChange}
                    value={value}
                    errors={errors.direccion?.message}
                  />
                )}
              />
              <Controller
                name="codigoPostal"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <IngTFNumeric
                    label="Código Postal: "
                    onChange={onChange}
                    value={value}
                    errors={errors.codigoPostal?.message}
                  />
                )}
              />
            </div>
            <div className="varForm--textFields--content">
              <Controller
                name="telefono"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <IngTFNumeric
                    label="Teléfono: "
                    onChange={onChange}
                    value={value}
                    errors={errors.telefono?.message}
                  />
                )}
              />
              <Controller
                name="email"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <IngTextField
                    label="Email: "
                    onChange={onChange}
                    value={value}
                    errors={errors.email?.message}
                  />
                )}
              />
              <Controller
                name="numDeCliente"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <IngTFNumeric
                    label="Número de cliente: "
                    onChange={onChange}
                    value={value}
                    errors={errors.numDeCliente?.message}
                  />
                )}
              />
              <Controller
                name="poblacion"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <IngTextField
                    label="Población: "
                    onChange={onChange}
                    value={value}
                    errors={errors.poblacion?.message}
                  />
                )}
              />
              <Controller
                name="provincia"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <IngTextField
                    label="Provincia: "
                    onChange={onChange}
                    value={value}
                    errors={errors.provincia?.message}
                  />
                )}
              />
            </div>
          </div>
          <div className="varForm--buttons">
            <IngButton
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              label="Guardar"
              endIcon={<SaveOutlinedIcon />}
            />
          </div>
        </form>
        {params.clienteId !== "nuevo" && (
          <>
            <hr />
            <div className="varForm--table">
              <div className="varForm--title">
                <ContactsIcon fontSize="large" />{" "}
                <div className="titleText">Contactos</div>
              </div>
              {contactos ? (
                <>
                  <div className="buscadorCards">
                    <Buscador submit={submitBuscadorContactos} />
                    <IngButton
                      onClick={() => anadir()}
                      variant="contained"
                      label="Añadir"
                      disabled={params.clienteId === "nuevo"}
                      endIcon={<AddCircleOutlineOutlinedIcon />}
                    ></IngButton>
                  </div>

                  <IngTable
                    data={filterBuscador(contactos, cadenaBusquedaContactos)}
                    titulos={titlesContacto}
                    id="id"
                    columnas={columnsContacto}
                    onClickEdit={(id) => {
                      setContactoId(id);
                      handleClickOpen();
                    }}
                    onClickDelete={(id) => {
                      setContactoId(id);
                      setOpenConfirmarEliminarContacto(true);
                    }}
                    altura={60}
                    buscador={false}
                    crud="crud"
                    pagination={true}
                  />
                  <ContactoDialog
                    open={open}
                    onClose={handleClose}
                    actualizar={actualizar}
                    id={contactoId}
                    clienteId={parseInt(params.clienteId)}
                  ></ContactoDialog>
                </>
              ) : (
                <Cargando />
              )}
            </div>
            <hr />
            <div className="varForm--table">
              <div className="varForm--title">
                <ArticleIcon fontSize="large" />{" "}
                <div className="titleText">Presupuestos</div>
              </div>
              {presupuestos ? (
                <>
                  <div className="buscadorCards">
                    <Buscador submit={submitBuscadorPresupuestos} />
                    <IngButton
                      onClick={() => setOpenConfirmarInsertar(true)}
                      variant="contained"
                      label="Añadir"
                      disabled={params.clienteId === "nuevo"}
                      endIcon={<AddCircleOutlineOutlinedIcon />}
                    ></IngButton>
                  </div>

                  <ConfirmDialog
                    aceptar={insertarPresupuesto}
                    open={openConfirmarInsertar}
                    onClose={handleCloseConfirmarInsertar}
                    title={"Crear presupuesto"}
                    text={
                      "Seguro que deseas crear un presupuesto para " +
                      getValues().nombre +
                      "?"
                    }
                    id={undefined}
                  />

                  <ConfirmDialog
                    aceptar={eliminarPresupuesto}
                    open={openConfirmarEliminarPresupuesto}
                    onClose={handleCloseConfirmarEliminarPresupuesto}
                    title={"Eliminar presupuesto"}
                    id={presupuestoId}
                    text={"Seguro que deseas eliminar el presupuesto?"}
                  />

                  <ConfirmDialog
                    aceptar={eliminar}
                    open={openConfirmarEliminarContacto}
                    onClose={handleCloseConfirmarEliminarContacto}
                    title={"Eliminar contacto"}
                    id={contactoId}
                    text={"Seguro que deseas eliminar el contacto?"}
                  />

                  <IngTable
                    data={filterBuscador(
                      presupuestos,
                      cadenaBusquedaPresupuestos
                    )}
                    titulos={titlesPresupuesto}
                    id="id"
                    columnas={columnsPresuuesto}
                    onClickEdit={(id) => {
                      navigate("/presupuesto/" + id);
                    }}
                    onClickDelete={(id) => {
                      setPresupuestoId(id);
                      setOpenConfirmarEliminarPresupuesto(true);
                    }}
                    altura={60}
                    buscador={false}
                    crud="crud"
                    pagination={true}
                  />
                  {ok ? (
                    <Snackbar
                      className="snackbar"
                      open={openA}
                      autoHideDuration={6000}
                      onClose={handleCloseA}
                    >
                      {/* @ts-ignore*/}
                      <Alert
                        onClose={handleCloseA}
                        severity="success"
                        sx={{ width: "100%" }}
                      >
                        Operación realizada con éxito!
                      </Alert>
                    </Snackbar>
                  ) : (
                    <></>
                  )}
                  {bad ? (
                    <Snackbar
                      className="snackbar"
                      open={openA}
                      autoHideDuration={6000}
                      onClose={handleCloseA}
                    >
                      {/* @ts-ignore*/}
                      <Alert
                        onClose={handleCloseA}
                        severity="error"
                        sx={{ width: "100%" }}
                      >
                        Error en la operación!
                      </Alert>
                    </Snackbar>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Cargando />
              )}
            </div>
          </>
        )}
      </Card>
    </div>
  );
}
