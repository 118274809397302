import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "../../contexts/AppContext";
import CardCliente from "../moleculas/CardCliente";
import Buscador from "../moleculas/Buscador";
import IngButton from "../atomos/IngButton";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import Cargando from "../atomos/Cargando";
import Auth from "../../Auth";
import { deleteCliente, getClientes } from "../../services/APICliente";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import NoData from "../atomos/NoData";
import PersonIcon from "@mui/icons-material/Person";

/**
 * Pagina con la lista de cards de clientes
 * @name PaginaClientes
 * @Component
 */
export default function Clientes() {
  const [loaded, setLoaded] = useState(false);
  //alert
  const [bad, setBad] = useState(false);
  const [ok, setOk] = useState(false);
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const [openA, setOpenA] = React.useState(false);
  const handleCloseA = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenA(false);
  };
  //finAlert
  const { setGlobal } = useContext(AppContext);
  const [clientes, setClientes] = useState();
  const [cadenaBusqueda, setCadenaBusqueda] = useState("");
  const navigate = useNavigate();
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    setGlobal((old) => ({
      ...old,
      pageTitle: (
        <>
          <PersonIcon fontSize="large" />
          <div className="titleText">Clientes</div>
        </>
      ),
    }));
    getClientes()
      .then((res) =>
        res.status === 401
          ? Auth.logout()
          : res.ok
          ? res.json()
          : setLoaded(true)
      )
      .then((json) => setClientes(json));
  }, [setGlobal, update]);

  function filterBuscador(array, searchQuery) {
    if (!searchQuery) return array;
    return array?.filter((o) =>
      Object.keys(o).some(
        (k) =>
          typeof o[k] === "string" &&
          o[k].toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }

  const actualizar = () => {
    setUpdate((old) => !old);
  };

  const submit = (event) => {
    setCadenaBusqueda(event.target.value);
  };

  function anadir() {
    navigate("/cliente/nuevo");
  }

  function eliminar(id) {
    deleteCliente(id)
      .then((res) =>
        res.status === 401 ? Auth.logout() : res.ok ? res.json() : null
      )
      .then((json) => {
        if (json) {
          setOk(true);
          setBad(false);
          setOpenA(true);
          json.id ? actualizar() : console.log(json.message);
        } else {
          setOk(false);
          setBad(true);
          setOpenA(true);
        }
      });
  }

  return (
    <div className="bodyList">
      {clientes ? (
        <>
          <div className="buscadorCards">
            <div className="buscador2">
              <Buscador submit={submit} />
            </div>
            <IngButton
              onClick={anadir}
              variant="contained"
              label="Añadir"
              endIcon={<PersonAddIcon />}
            ></IngButton>
          </div>
          {filterBuscador(clientes, cadenaBusqueda).length > 0 ? (
            <div className="cardList">
              {filterBuscador(clientes, cadenaBusqueda).map((cliente) => (
                <CardCliente
                  key={cliente.id}
                  cliente={cliente}
                  eliminar={eliminar}
                />
              ))}
            </div>
          ) : (
            <NoData text={undefined} />
          )}
          {ok ? (
            <Snackbar
              className="snackbar"
              open={openA}
              autoHideDuration={3000}
              onClose={handleCloseA}
            >
              {/* @ts-ignore*/}
              <Alert
                onClose={handleCloseA}
                severity="success"
                sx={{ width: "100%" }}
              >
                Operación realizada con éxito!
              </Alert>
            </Snackbar>
          ) : (
            <></>
          )}
          {bad ? (
            <Snackbar
              className="snackbar"
              open={openA}
              autoHideDuration={3000}
              onClose={handleCloseA}
            >
              {/* @ts-ignore*/}
              <Alert
                onClose={handleCloseA}
                severity="error"
                sx={{ width: "100%" }}
              >
                Error en la operación!
              </Alert>
            </Snackbar>
          ) : (
            <></>
          )}
        </>
      ) : loaded ? (
        <NoData text="⚠️ No se ha podido conectar con la base de datos." />
      ) : (
        <Cargando />
      )}
    </div>
  );
}
