import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardHeader } from "@mui/material";
import { CardActionArea } from "@mui/material";


export default function IngCard(props) {
  const onClick = () => {
    
    props.onClick();
  };

  return (
    <Card className={props.className} sx={{ maxWidth: 345 }}>
      <CardActionArea sx={{height:'100%'}} onClick={onClick}>
        <CardHeader className="cardHeader" title={props.titulo} />
        <CardContent className="cardContent">
          
            {props.contenido}
         
        </CardContent>
      </CardActionArea>

      
    </Card>
  );
}
