import { useState, useEffect } from "react";
// Lib Components
import { Routes, Route } from "react-router-dom";
// Own Components
import Auth from "./Auth";
import Main from "./components/templates/Main";
import Home from "./components/pages/Home";
import Piezas from "./components/pages/Piezas";
import RangoBollos from "./components/pages/RangoBollos";
import Precios from "./components/pages/Precios";
import Clientes from "./components/pages/Clientes";
import Presupuestos from "./components/pages/Presupuestos";
import ClienteForm from "./components/pages/ClienteForm";
import PresupuestoForm from "./components/pages/PresupuestoForm";
import Login from "./components/pages/Login";
import "./custom.css";

export default function App() {
  const [token, setToken] = useState(false);

  useEffect(() => {
    var aux = localStorage.getItem("token");
    aux ? setToken(true) : setToken(false);
    //para saltarme el login
    //setToken(true);
  }, [token]);

  return (
    <>
      {token ? (
        <Main>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/presupuestos" element={<Presupuestos />} />
            <Route
              path="/presupuesto/:presupuestoId"
              element={<PresupuestoForm />}
            />
            <Route path="/clientes" element={<Clientes />} />
            <Route path="/cliente/:clienteId" element={<ClienteForm />} />
            <Route path="/piezas" element={<Piezas />} />
            <Route path="/rango_bollos" element={<RangoBollos />} />
            <Route path="/precios" element={<Precios />} />
          </Routes>
        </Main>
      ) : (
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route path="*" element={<Login />} />
        </Routes>
      )}
    </>
  );
}
