import PersonIcon from "@mui/icons-material/Person";
import ArticleIcon from "@mui/icons-material/Article";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import HandymanIcon from "@mui/icons-material/Handyman";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "../atomos/ConfirmDialog";

import moment from "moment";
import "./CardPresupuesto.css";
import { useState } from "react";

/**
 * Card para la lista de presupuestos
 * @name CardPresupuesto
 * @Component
 * @param {Object} props – parametros de la clase
 * @param {Object} props.presupuesto - Objeto presupuesto
 * @param {function} props.eliminar - función para eliminar el cliente
 */
export default function CardPresupuesto(props) {
  const navigate = useNavigate();
  const { presupuesto, eliminar } = props;
  const {
    id,
    cliente,
    vehiculo,
    estadoPresupuesto,
    codigoAnyo,
    codigoNumero,
    fecha,
    color,
  } = presupuesto;

  // CONFIRMACION

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  function delPres() {
    eliminar(id);
  }

  return (
    <div className="card">
      <div className={color}>
        <ArticleIcon fontSize="large" /> &nbsp; Presupuesto:{" "}
        {codigoAnyo + "/" + codigoNumero}
      </div>
      <hr />
      <div className="card--content">
        <span className="card--label">
          <PersonIcon fontSize="small" />
          &nbsp; Cliente:
        </span>
        &nbsp;
        {cliente}
      </div>
      <div className="card--content">
        <span className="card--label">
          <DirectionsCarIcon fontSize="small" /> &nbsp; Vehículo:
        </span>
        &nbsp;
        {vehiculo !== "" ? vehiculo : "No seleccionado"}
      </div>
      <div className="card--content">
        <span className="card--label">
          <CalendarMonthIcon fontSize="small" />
          &nbsp; Fecha:
        </span>
        &nbsp;
        {moment(fecha).format("DD-MM-YYYY")}
      </div>
      <div className="card--content">
        <span className="card--label">
          <HandymanIcon fontSize="small" />
          &nbsp; Estado:
        </span>
        &nbsp;
        {estadoPresupuesto}
      </div>
      <hr />
      <div className="card--button">
        <div
          className="card--label"
          onClick={() => navigate("/presupuesto/" + id)}
        >
          DETALLE &nbsp; <ArrowForwardIcon fontSize="small" />
        </div>
        <div className="card--label" onClick={() => setOpen(true)}>
          <DeleteIcon fontSize="small" /> ELIMINAR
        </div>
      </div>
      <ConfirmDialog
        aceptar={delPres}
        open={open}
        onClose={handleClose}
        title={"Eliminar presupuesto"}
        text={
          "¿Seguro que desea eliminar el Presupuesto " +
          codigoAnyo +
          "/" +
          codigoNumero +
          "?"
        }
        id={undefined}
      />
    </div>
  );
}
