import { IconButton, InputAdornment, TextField } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3A86A2",
    },
    secondary: {
      main: "#000000",
    },
    error: {
      main: "#FC5454",
    },
    warning: {
      main: "#FDF55E",
    },
    info: {
      main: "#00348e",
    },
    success: {
      main: "#179A54",
    },
  },
});

/**
 * Ing Textfield Numeric personalizado
 * @name TextFieldConBotonOcultar
 * @Component
 * @param {Object} props – parametros de la clase
 * @param {String} [props.className] - estilo personalizado del Textefield
 * @param {React.ReactNode} [props.label] - título del textfield
 * @param {unknown} [props.value] - value del TextField
 * @param {React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>} [props.onChange] - función onChange del TextField
 * @param {boolean} [props.negativo] - boolean que controla si se admiten valores negativos
 * @param {boolean} [props.disabled] - boolean que controla la habilitación/deshabilitación del TextField
 * @param {String} [props.placeholder] - placeholder del Textfield
 * @param {String} [props.errors] - texto de error para los validadores
 * @param {boolean} [props.multiline] - controla si el textfield es expandible en función del contenido
 * @param {boolean} [props.labelUp] - Muestra el label siempre arriba
 * @param {boolean} [props.readOnly] - Campo editable
 * @param {function} [props.action] - Icon action
 * @param {String} [props.type] - Input type
 */
export default function TextFieldDescuento(props) {
  return (
    <ThemeProvider theme={theme}>
      <div>
        <TextField
          // id="custom-css-outlined-input"
          label={props.label}
          variant="outlined"
          value={props.value}
          onChange={props.onChange}
          disabled={props.disabled}
          placeholder={props.placeholder}
          InputLabelProps={{ shrink: props.labelUp }}
          multiline={props.multiline ? props.multiline : undefined}
          type={props.type || "text"}
          InputProps={{
            readOnly: props.readOnly,
            endAdornment: (
              <InputAdornment position="start">
                <IconButton
                  disabled={props.readOnly}
                  onClick={() => props.action()}
                >
                  <VisibilityOffOutlinedIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            ),
          }}
          color={props.readOnly ? "secondary" : "primary"}
        />
        <div className="errorYup">{props.errors}</div>
      </div>
    </ThemeProvider>
  );
}
