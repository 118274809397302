export function LoginPost(datos) {
    return (
        fetch("/api/login", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(datos),
          })
    );
}

