import * as React from "react";
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "black",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "gray",
    },
    "&:hover fieldset": {
      borderColor: "black",
    },
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
});

/**
 * Ing Textfield Password personalizado
 * @name TextFieldPasswordIngesis
 * @Component
 * @param {Object} props – parametros de la clase
 * @param {String} [props.className] - estilo personalizado del TextField
 * @param {React.ReactNode} [props.label] - título del textfield
 * @param {unknown} [props.value] - value del TextField
 * @param {React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>} [props.onChange] - función onChange del TextField
 * @param {boolean} [props.disabled] - boolean que controla la habilitación/deshabilitación del TextField
 * @param {String} [props.placeholder] - placeholder del Textfield
 * @param {String} [props.errors] - texto de error para los validadores
 * @param {boolean} [props.multiline] - controla si el textfield es expandible en función del contenido
 * @param {React.KeyboardEventHandler<HTMLDivElement>} [props.onKeyPress] - función para enter
 */
export default function IngTFpassword(props) {
  return (
    <div>
      <CssTextField
        className={props.className ? props.className : "textfield"}
        // id="custom-css-outlined-input"
        label={props.label}
        variant="outlined"
        value={props.value}
        onChange={props.onChange}
        disabled={props.disabled ? props.disabled : undefined}
        placeholder={props.placeholder}
        multiline={props.multiline ? props.multiline : undefined}
        type="password"
        onKeyPress={props.onKeyPress}
      />
      <div className="errorYup">{props.errors}</div>
    </div>
  );
}
