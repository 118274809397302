import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import ReactDOM from "react-dom";
//import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { AppContextProvider } from "./contexts/AppContext";
import "./custom.css";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const container = document.getElementById("root");

// PARA REACT 18
//const root = createRoot(container);

/*root.render(
  <BrowserRouter basename={baseUrl}>
    <AppContextProvider initValue={{}}>
      <App />
    </AppContextProvider>
  </BrowserRouter>
);*/

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <AppContextProvider initValue={{}}>
      <App />
    </AppContextProvider>
  </BrowserRouter>,
  container
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
