import * as React from "react";
import IngButton from "../atomos/IngButton";
import IngTFpassword from "../atomos/IngTFpassword";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import { LoginPost } from "../../services/APILogin";
import { useState } from "react";
import IngTextFieldDefault from "../atomos/IngTextFieldDefault";
// @ts-ignore
import logo from "../img/logo.png";
import pack from "../../../package.json";

/**
 * Pagina de login
 * @name Login
 * @Component
 */
export default function Login() {
  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");

  const [bad, setBad] = useState(false);
  //alert
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  //fin Alert

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onClick();
    }
  };
  const onClick = () => {
    const datos = { username: user, password: pass };
    LoginPost(datos)
      .then((response) => (response.ok ? response.json() : null))
      .then((data) => {
        if (data != null) {
          localStorage.setItem("token", JSON.stringify(data));
          window.location.replace("/");
        } else {
          setBad(true);
          setOpen(true);
        }
      });
  };
  return (
    <div className="bgImagen">
      <div className="login">
        <div className="login2">
          {/* <h2 className="nombreApp">Varilleros App</h2> */}
          <img data-testid="logo" className="logoLogin" alt="" src={logo} />
          <IngTextFieldDefault
            className="textfield3"
            label="Usuario"
            onChange={(t) => setUser(t.target.value)}
            value={user}
            onKeyPress={handleKeyPress}
          />
          <IngTFpassword
            className="textfield3"
            label="Password"
            onChange={(t) => setPass(t.target.value)}
            value={pass}
            onKeyPress={handleKeyPress}
          />
          <IngButton
            className="buttonLogin"
            onClick={onClick}
            variant="contained"
            label="ENTRAR"
            endIcon={<LoginOutlinedIcon />}
          />
        </div>
        <div className="versionNumber">v.{pack.version}</div>
      </div>

      {bad ? (
        <Snackbar
          className="snackbar"
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          {/*@ts-ignore*/}
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            Usuario y/o contraseña inválidos!
          </Alert>
        </Snackbar>
      ) : (
        <></>
      )}
    </div>
  );
}
