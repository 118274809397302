/**
 * Componente para substituir las paginas o tablas cuando no hay datos o los filtros no coinciden con nada
 * @name NoData
 * @Component
 * @param {Object} props – parametros de la clase
 * @param {String} props.text - Texto que se muestra en el componente.
 */
export default function NoData(props) {
  const { text } = props;
  return (
    <div className="bodyList">
      <div className="noData">
        {text ? text : "No se han encontrado datos."}
      </div>
    </div>
  );
}
