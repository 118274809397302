import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Dialog } from "@mui/material";
import IngButton from "../atomos/IngButton";
import "./Dialog.css";
import IngSelectorBasic from "../atomos/IngSelectorBasic";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import IngTextField from "../atomos/IngTextField";
//import { getModelos } from "../../services/APIModelo";
import { getVehiculo, putVehiculo } from "../../services/APIVehiculo";
import Auth from "../../Auth";

/**
 * Dialog para modificar a el vehiculo del presupuesto
 * @name SelectVehiculoDialog
 * @Component
 * @param {Object} props – parametros de la clase
 * @param {boolean} props.open – Estado de apertura del dialog
 * @param {*} props.onClose – Funcion de cerrar el dialog
 * @param {function} props.actualizar – funcion a ejecutar al aceptar
 * @param {number} props.vehiculoId – Id del vehiculo
 */
export default function SelectVehiculoDialog({
  open,
  onClose,
  actualizar,
  vehiculoId,
}) {
  //const [modelos, setModelos] = useState([]);

  const schema = yup
    .object({
      /*modelo: yup
        .object()
        .required("Debes de seleccionar un modelo.")
        .typeError("Debes de seleccionar un modelo."),*/
      marca: yup.string().required("Debes de introducir una marca."),
      modelo: yup.string().required("Debes de introducir un modelo"),
      matricula: yup.string().required("Debes de introducir una matricula."),
      color: yup.string().required("Debes de introducir un color."),
    })
    .required();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      marca: "",
      modelo: "",
      matricula: "",
      color: "",
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    open === true &&
      vehiculoId &&
      getVehiculo(vehiculoId)
        .then((res) =>
          res.status === 401 ? Auth.logout() : res.ok ? res.json() : null
        )
        .then((json) => json && reset(json));

    /*open === true &&
      getModelos()
        .then((res) =>
          res.status === 401 ? Auth.logout() : res.ok ? res.json() : null
        )
        .then((json) => json && setModelos(json));*/
  }, [vehiculoId, open, reset]);

  const onSubmit = (data) => {
    //data.modeloId = data.modelo.id;

    if (vehiculoId) {
      data.id = vehiculoId;
      putVehiculo(vehiculoId, data).then((res) =>
        res.status === 401 ? Auth.logout() : res.ok ? actualizar(data) : null
      );
      onClose();
    } else {
    }
  };

  return (
    <Dialog open={open} onClose={onClose} onSubmit={handleSubmit(onSubmit)}>
      <form className="dialog--one">
        <div className="dialog--one--content">
          <div className="dialog--one--title">
            <h3>Seleccionar vehiculo:</h3>
          </div>
          <div className="dialog--one--row">
            <div className="dialog--precio--column">
              <Controller
                name="marca"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <IngTextField
                    label="Marca: "
                    onChange={onChange}
                    value={value}
                    errors={errors.marca?.message}
                  />
                )}
              />
              <Controller
                name="modelo"
                control={control}
                render={({ field: { onChange, value } }) => (
                  /*<IngSelectorBasic
                    options={modelos}
                    getOptionLabel={(option) =>
                      option.marca + " " + option.nombre
                    }
                    className={"dialog--precio--selector"}
                    label={"Modelo: "}
                    value={value}
                    onChange={(event, option) => {
                      onChange(option);
                    }}
                    disableClearable={true}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    errors={errors.modelo?.message}
                  />*/
                  <IngTextField
                    label="Modelo: "
                    onChange={onChange}
                    value={value}
                    errors={errors.modelo?.message}
                  />
                )}
              />
            </div>

            <div className="dialog--precio--column">
              <Controller
                name="matricula"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <IngTextField
                    label="Matricula: "
                    onChange={onChange}
                    value={value}
                    errors={errors.matricula?.message}
                  />
                )}
              />
              <Controller
                name="color"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <IngTextField
                    label="Color: "
                    onChange={onChange}
                    value={value}
                    errors={errors.color?.message}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dialog--one--buttons">
          <IngButton
            type="submit"
            variant="contained"
            label="Aceptar"
            color="secondary"
          ></IngButton>
          <IngButton
            onClick={onClose}
            variant="contained"
            color="error"
            label="Cancelar"
          ></IngButton>
        </div>
      </form>
    </Dialog>
  );
}
