import * as React from "react";

import Checkbox from "@mui/material/Checkbox";
import { Typography } from "@mui/material";

/**
 * Ing Checkbox personalizado
 * @name CheckBoxIngesis
 * @Component
 * @param {Object} props – parametros de la clase
 * @param {String} [props.className] - estilo personalizado del div del checkbox incluyendo su label
 * @param {String} [props.label] - texto del checkbox
 * @param {*} props.onChange - función para cuando cambie el valor del checkbox
 * @param {unknown} props.value - valor asociado al ckeckbox
 * @param {String} [props.errors] - mensaje de error para validadores de formularios
 * @param {boolean} [props.readOnly] - Campo editable
 */
export default function IngCheckBox(props) {
  return (
    <div>
      <div className={props.className ? props.className : "checkbox"}>
        <Typography>{props.label}</Typography>
        <Checkbox
          onChange={props.onChange}
          checked={props.value ? true : false}
          value={props.value}
        />
      </div>
      <div className="errorYup">{props.errors}</div>
    </div>
  );
}
