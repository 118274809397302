import React, { useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import IngButton from "../atomos/IngButton";
import IngTFNumeric from "../atomos/IngTFNumeric";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "./Dialog.css";
import IngCheckBox from "../atomos/IngCheckBox";
import IngSelectorBasic from "../atomos/IngSelectorBasic";
import { getPrecio, putPrecio, postPrecio } from "../../services/APIPrecio";
import { getPiezas } from "../../services/APIPieza";
import { getRangoBollos } from "../../services/APIRangoBollos";
import Auth from "../../Auth";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

/**
 * Dialog para crear o modificar precios
 * @name PrecioDialog
 * @Component
 * @param {Object} props – parametros de la clase
 * @param {boolean} props.open – Estado de apertura del dialog
 * @param {*} props.onClose – Funcion de cerrar el dialog
 * @param {function} props.actualizar – funcion a ejecutar al aceptar
 * @param {number} props.id – Id del precio
 */
export default function PrecioDialog({ open, onClose, actualizar, id }) {
  const [piezas, setPiezas] = useState([]);
  const [rangos, setRangos] = useState([]);
  //alert
  const [bad, setBad] = useState(false);
  const [ok, setOk] = useState(false);
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const [openA, setOpenA] = React.useState(false);

  const handleCloseA = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenA(false);
  };
  //finAlert
  const schema = yup
    .object({
      pieza: yup
        .object()
        .required("Debes de seleccionar una pieza.")
        .typeError("Debes de seleccionar una pieza."),
      rangoBollos: yup
        .object()
        .required("Debes de seleccionar un rango de bollos.")
        .typeError("Debes de seleccionar un rango de bollos."),
      destapizado: yup.boolean(),
      aluminio: yup.boolean(),
      precio: yup
        .number()
        .typeError("Debes de introducir un precio.")
        .required("Debes de introducir un precio."),
    })
    .required();

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      pieza: null,
      rangoBollos: null,
      destapizado: false,
      aluminio: false,
      precio: "",
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    // GET de Piezas
    open === true &&
      getPiezas()
        .then((res) =>
          res.status === 401 ? Auth.logout() : res.ok ? res.json() : null
        )
        .then((json) => setPiezas(json));
    // GET de Rangos
    open === true &&
      getRangoBollos()
        .then((res) =>
          res.status === 401 ? Auth.logout() : res.ok ? res.json() : null
        )
        .then((json) => setRangos(json));
    // GET de Precio
    if (id !== 0 && open === true) {
      getPrecio(id)
        .then((res) =>
          res.status === 401 ? Auth.logout() : res.ok ? res.json() : null
        )
        .then((json) => reset(json));
    } else {
      reset({
        pieza: null,
        rangoBollos: null,
        destapizado: false,
        aluminio: false,
        precio: "",
      });
    }
  }, [id, reset, open, actualizar]);

  const onSubmit = (data) => {
    data.piezaId = data.pieza.id;
    data.rangoBollosId = data.rangoBollos.id;

    data.id
      ? putPrecio(data.id, data)
          .then((res) =>
            res.status === 401 ? Auth.logout() : res.ok ? res.json() : null
          )
          .then((json) => {
            if (json) {
              setOk(true);
              setBad(false);
              setOpenA(true);
              actualizar();
              onClose();
              reset({
                pieza: null,
                rangoBollos: null,
                destapizado: false,
                aluminio: false,
                precio: "",
              });
            } else {
              setOk(false);
              setBad(true);
              setOpenA(true);
            }
          })
      : postPrecio(data)
          .then((res) =>
            res.status === 401 ? Auth.logout() : res.ok ? res.json() : null
          )
          .then((json) => {
            if (json) {
              setOk(true);
              setBad(false);
              setOpenA(true);
              actualizar();
              onClose();
              reset({
                pieza: null,
                rangoBollos: null,
                destapizado: false,
                aluminio: false,
                precio: "",
              });
            } else {
              setOk(false);
              setBad(true);
              setOpenA(true);
            }
          });
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} onSubmit={handleSubmit(onSubmit)}>
        <form className="dialog--precio">
          <div className="dialog--precio--content">
            <div className="dialog--precio--title">
              <h3>{id === 0 ? "Crear " : "Modificar "}precio:</h3>
            </div>
            <div className="dialog--precio--row">
              <div className="dialog--precio--column">
                <Controller
                  name="pieza"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <IngSelectorBasic
                      options={piezas}
                      getOptionLabel={(option) => option.nombre}
                      className="dialog--precio--selector"
                      label={"Pieza: "}
                      value={value}
                      onChange={(event, option) => {
                        onChange(option);
                      }}
                      disableClearable={true}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      errors={errors.pieza?.message}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="destapizado"
                  render={({ field: { onChange, value, name } }) => (
                    <IngCheckBox
                      label="Destapizado: "
                      className="dialog--precio--checkbox"
                      onChange={() => onChange(!getValues(name))}
                      value={value}
                      errors={errors.destapizado?.message}
                    />
                  )}
                />
              </div>
              <div className="dialog--precio--column">
                <Controller
                  name="rangoBollos"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <IngSelectorBasic
                      options={rangos}
                      getOptionLabel={(option) => option.nombre}
                      className="dialog--precio--selector"
                      label={"Rango bollos: "}
                      value={value}
                      disableClearable={true}
                      onChange={(event, option) => {
                        onChange(option);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      errors={errors.rangoBollos?.message}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="aluminio"
                  render={({ field: { onChange, value, name } }) => (
                    <IngCheckBox
                      label="Aluminio: "
                      className="dialog--precio--checkbox"
                      onChange={() => onChange(!getValues(name))}
                      value={value}
                      errors={errors.aluminio?.message}
                    />
                  )}
                />
              </div>
              <div className="dialog--precio--column">
                <Controller
                  name="precio"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <IngTFNumeric
                      label="Precio: "
                      onChange={onChange}
                      value={value}
                      errors={errors.precio?.message}
                    />
                  )}
                />
              </div>
            </div>
          </div>

          <div className="dialog--one--buttons">
            <IngButton
              type="submit"
              variant="contained"
              label="Aceptar"
              color="secondary"
            ></IngButton>
            <IngButton
              onClick={onClose}
              variant="contained"
              color="error"
              label="Cancelar"
            ></IngButton>
          </div>
        </form>
      </Dialog>
      {ok ? (
        <Snackbar
          className="snackbar"
          open={openA}
          autoHideDuration={3000}
          onClose={handleCloseA}
        >
          {/* @ts-ignore*/}
          <Alert
            onClose={handleCloseA}
            severity="success"
            sx={{ width: "100%" }}
          >
            Operación realizada con éxito!
          </Alert>
        </Snackbar>
      ) : (
        <></>
      )}
      {bad ? (
        <Snackbar
          className="snackbar"
          open={openA}
          autoHideDuration={3000}
          onClose={handleCloseA}
        >
          {/* @ts-ignore*/}
          <Alert onClose={handleCloseA} severity="error" sx={{ width: "100%" }}>
            Error en la operación!
          </Alert>
        </Snackbar>
      ) : (
        <></>
      )}
    </>
  );
}
